import React from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
    BarChartOutlined,
    BugOutlined,
    CalendarOutlined,
    ContainerOutlined,
    DollarCircleOutlined,
    LoginOutlined,
    LogoutOutlined,
    MenuOutlined,
    PlusSquareOutlined,
    QuestionCircleOutlined,
    StarOutlined,
    UnorderedListOutlined,
    UserAddOutlined,
    UserOutlined,
    UsergroupDeleteOutlined,
    WalletOutlined
} from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Space, Typography } from "antd";
import { logoutUser } from "./actions/user";
import {
    getLoginUrl,
    getSignUpUrl,
    redirectToLogin,
    redirectToLogout,
    redirectToSignUp
} from "./utils";

const { Text } = Typography;

const LoginLogoutButtons = ({ user, isAuthenticated, logoutUser }) => {
    const history = useHistory();
    const location = useLocation();

    const handleMenuClick = (e) => {
        switch (e.key) {
            case "1":
                return history.push("/collections/");
            case "2":
                return history.push("/my-collections/");
            case "3":
                return history.push("/scheduled-licenses");
            case "4":
                return history.push("/creator/home/");
            case "5":
                return history.push(`/creators/${user.id}/`);
            case "6":
                return history.push(`/authors/`);
            case "7":
                return history.push(`/aboutus/`);
            case "8":
                return history.push(`/frequently-asked-questions/`);
            case "9":
                return history.push("/report-issue/");
            case "10":
                localStorage.removeItem("access");
                localStorage.removeItem("refresh");
                message.loading("Logging out...");
                logoutUser();
                return setTimeout(() => {
                    message.success("You are now logged out");
                    return redirectToLogout();
                }, 3000);
            case "11":
                return redirectToSignUp();
            case "12":
                return redirectToLogin();
            case "13":
                return history.push("/account/");
            case "14":
                return history.push("/admin");
            default:
                return;
        }
    };

    /*<Menu.Item key="1"><Link to='/collections/'>Premium Courses</Link></Menu.Item>
    { this.props.isAuthenticated ? <Menu.Item key="2"><Link to='/my-collections/'>My Courses</Link></Menu.Item> : null}
    { this.props.isAuthenticated ? <Menu.Item key="3"><Link to='/dashboard/'>Dashboard</Link></Menu.Item> : null}
    
    <Menu.Item key="4"><Link to='/aboutus/'>About Us</Link></Menu.Item>
    <Menu.Item key="5"><Link to='/frequently-asked-questions/'>FAQ</Link></Menu.Item>
*/

    const creatorMenu = () => (
        <Menu onClick={handleMenuClick}>
            <Menu.ItemGroup title="Watch">
                <Menu.Item key="1" icon={<StarOutlined />}>
                    Premium Courses
                </Menu.Item>
                <Menu.Item key="2" icon={<ContainerOutlined />}>
                    My Courses
                </Menu.Item>
                <Menu.Item key="3" icon={<CalendarOutlined />}>
                    My Classes
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.Divider />
            <Menu.ItemGroup title="Create">
                <Menu.Item key="4" icon={<PlusSquareOutlined />}>
                    <Text>Build/Edit Courses</Text>
                </Menu.Item>
                <Menu.Item key="5" icon={<DollarCircleOutlined />}>
                    <Text>My Profile and Earnings</Text>
                </Menu.Item>
            </Menu.ItemGroup>

            <Menu.Divider />
            <Menu.ItemGroup title="Analyze">
                <Menu.Item key="6" icon={<UsergroupDeleteOutlined />}>
                    <Text>Authors</Text>
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.Divider />
            <Menu.ItemGroup title="Account">
                <Menu.Item key="13" icon={<WalletOutlined />}>
                    <Text>Account / Wallet</Text>
                    <br />
                    <Text type="secondary">
                        {user.primaryWallet.cupointsBalance} cupoints
                    </Text>
                </Menu.Item>
            </Menu.ItemGroup>
            {user.isAdmin ? (
                <>
                    <Menu.ItemGroup title="Admin">
                        <Menu.Item key="14" icon={<UnorderedListOutlined />}>
                            Admin Home
                        </Menu.Item>
                    </Menu.ItemGroup>
                    <Menu.Divider />
                </>
            ) : (
                <></>
            )}
            <Menu.ItemGroup title="Cubits">
                <Menu.Item key="7" icon={<UnorderedListOutlined />}>
                    About Us
                </Menu.Item>
                <Menu.Item key="8" icon={<QuestionCircleOutlined />}>
                    FAQ
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.Divider />
            <Menu.Item
                key="9"
                icon={
                    <Text type="warning">
                        <BugOutlined />
                    </Text>
                }>
                <Text type="warning">Report an Issue</Text>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="10" icon={<LogoutOutlined />} danger>
                Logout
            </Menu.Item>
        </Menu>
    );

    const generalUserMenu = () => (
        <Menu onClick={handleMenuClick}>
            <Menu.ItemGroup title="Watch">
                <Menu.Item key="1" icon={<StarOutlined />}>
                    Premium Courses
                </Menu.Item>
                <Menu.Item key="2" icon={<ContainerOutlined />}>
                    My Courses
                </Menu.Item>
                <Menu.Item key="3" icon={<CalendarOutlined />}>
                    My Classes
                </Menu.Item>
            </Menu.ItemGroup>

            <Menu.Divider />

            <Menu.ItemGroup title="Analyze">
                <Menu.Item key="6" icon={<BarChartOutlined />}>
                    <Text>Authors</Text>
                </Menu.Item>
            </Menu.ItemGroup>

            <Menu.Divider />

            <Menu.ItemGroup title="Account">
                <Menu.Item key="13" icon={<WalletOutlined />}>
                    <Text>Account / Wallet</Text>
                    <br />
                    <Text type="secondary">
                        {user.primaryWallet.cupointsBalance} cupoints
                    </Text>
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.Divider />
            <Menu.ItemGroup title="Cubits">
                <Menu.Item key="7" icon={<UnorderedListOutlined />}>
                    About Us
                </Menu.Item>
                <Menu.Item key="8" icon={<QuestionCircleOutlined />}>
                    FAQ
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.Divider />
            <Menu.Item
                key="9"
                icon={
                    <Text type="warning">
                        <BugOutlined />
                    </Text>
                }
                danger>
                <Text type="warning">Report an Issue</Text>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="10" icon={<LogoutOutlined />} danger>
                Logout
            </Menu.Item>
        </Menu>
    );

    const menu = user.isContentCreator ? creatorMenu : generalUserMenu;

    const unAuthenticatedMenu = (
        <Menu onClick={handleMenuClick}>
            <Menu.ItemGroup title="Watch">
                <Menu.Item key="1" icon={<StarOutlined />}>
                    Premium Courses
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.Divider />
            <Menu.ItemGroup title="Cubits">
                <Menu.Item key="7" icon={<UnorderedListOutlined />}>
                    About Us
                </Menu.Item>
                <Menu.Item key="8" icon={<QuestionCircleOutlined />}>
                    FAQ
                </Menu.Item>
            </Menu.ItemGroup>
            <Menu.Divider />
            <Menu.ItemGroup title="Account">
                <Menu.Item key="12" icon={<LoginOutlined />}>
                    Login
                </Menu.Item>
                <Menu.Item
                    key="11"
                    icon={
                        <Text type="success">
                            <UserAddOutlined />
                        </Text>
                    }>
                    <Text type="success">Sign Up</Text>
                </Menu.Item>
            </Menu.ItemGroup>
        </Menu>
    );

    if (!isAuthenticated) {
        return (
            <div>
                <Space
                    id="unauthenticated-login-register-btn-wrapper"
                    size="middle">
                    <Button
                        className="login-btn"
                        type="primary"
                        onClick={() => redirectToSignUp()}>
                        <a href={`${getSignUpUrl()}`}>Sign Up</a>
                    </Button>
                    <Button
                        className="login-btn"
                        onClick={() => redirectToSignUp()}>
                        <a href={`${getLoginUrl()}`}>Login</a>
                    </Button>
                </Space>

                <Dropdown.Button
                    className="unauthenticated-menu"
                    overlay={unAuthenticatedMenu}
                    placement="bottomRight"
                    icon={<MenuOutlined />}>
                    <Link to={`/account`}>
                        <Space>
                            <UserOutlined />
                            Not logged In
                        </Space>
                    </Link>
                </Dropdown.Button>
            </div>
        );
    } else {
        return (
            <Space id="login-register-btn-wrapper">
                <Dropdown.Button
                    overlay={menu}
                    placement="bottomRight"
                    icon={<MenuOutlined />}>
                    <Link to={`/account`}>
                        <Space>
                            <UserOutlined />
                            {user.username}
                        </Space>
                    </Link>
                </Dropdown.Button>
            </Space>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.userReducer.isAuthenticated
    };
};

export default connect(mapStateToProps, { logoutUser })(LoginLogoutButtons);
