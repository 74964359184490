import ReactGA from "react-ga4";
import TRACKING_EVENTS from "./trackingEvents";

class GoogleTracking {
    GOOGLE_ANALYTICS_KEY = "G-27FWSC3TZ8"; // LIVE
    // GOOGLE_ANALYTICS_KEY = "G-BTX8T3RBLZ"; // TEST

    isEnabled = false;
    constructor() {}

    // Initialize Google Tracking
    initialize() {
        const debugMode = false;
        const initOptions = {
            gaOptions: {
                debug_mode: debugMode
            },
            gtagOptions: {
                debug_mode: debugMode
            }
        };
        ReactGA.initialize(this.GOOGLE_ANALYTICS_KEY, initOptions);
        this.isEnabled = !!window.gtag;

        // Track Page Load Event
        this.trackEvent(TRACKING_EVENTS.PAGE_LOAD);
    }

    // Track events
    trackEvent(eventName, eventData = {}) {
        if (!this.isEnabled) return;
        window.gtag("event", eventName, eventData);
    }
}

export default GoogleTracking;
