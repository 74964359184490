import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import adminReducer from "./reducers/admin";
import collectionReducer from "./reducers/collection";
import contentCreationReducer from "./reducers/contentCreation";
import issueReducer from "./reducers/issue";
import dashboardReducer from "./reducers/dashboard";
import creatorReducer from "./reducers/creator";
import userReducer from "./reducers/user";
import videoControlReducer from "./reducers/videoControl";
import collectionCertificationReducer from "./reducers/certificate";
import "./index.css";
import App from "./App";
import "babel-polyfill";
import reportWebVitals from "./reportWebVitals";
import ScrollToTop from "./ScrollToTop";
import { IS_PROD, MOBILE_URL } from "./const";
import { isMobile } from "react-device-detect";
import trackingInstance from "./_tracking/Tracking";

if (IS_PROD && isMobile) {
    window.location.replace(MOBILE_URL);
}
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducers = combineReducers({
    adminReducer,
    collectionReducer,
    contentCreationReducer,
    creatorReducer,
    dashboardReducer,
    issueReducer,
    userReducer,
    videoControlReducer,
    collectionCertificationReducer
});
const enhancer = IS_PROD
    ? applyMiddleware(thunk)
    : composeEnhancer(applyMiddleware(thunk));
const store = createStore(reducers, enhancer);

// Initiate Tracking
trackingInstance.init();

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <ScrollToTop>
                <App />
            </ScrollToTop>
        </Router>
    </Provider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
