import { Space, Skeleton } from "antd";

/** Certificate Loading Container */
const CertificateCollectionCardLoading = ({ imagePreviewHeight = "200px" }) => {
    return (
        <>
            <div className="certificate-collection-card">
                <div
                    className="certificate-collection-card-image-bio"
                    style={{ height: imagePreviewHeight }}>
                    <div className="collection-info-card-preview-image-loading"></div>
                </div>
                <div className="certificate-collection-card-text">
                    <Skeleton
                        loading={true}
                        title
                        active
                        paragraph={{ rows: 1 }}
                        button
                    />

                    <Space>
                        <Skeleton.Button
                            loading={true}
                            active
                            size={"small"}
                            shape={"default"}
                            block
                        />
                        <Skeleton.Button
                            loading={true}
                            active
                            size={"small"}
                            shape={"default"}
                            block
                        />
                    </Space>
                </div>
            </div>
        </>
    );
};

export default CertificateCollectionCardLoading;
