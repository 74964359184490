import { Component } from "react";
import { connect } from "react-redux";
import { Col, List, message, Row, Typography, Skeleton } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";
import {
    fetchSingleCollection,
    fetchSingleCollectionAccess
} from "../../actions/collection";
import { fetchCollectionDetailScheduledLicenses } from "../../actions/contentCreation";
import {
    fetchCollectionCertificationDetails,
    evaluateCollectionCertification,
    requestCollectionCertification
} from "../../actions/certification";
import CollectionModulesList from "../../Components/CollectionModulesList";
import moment from "moment";
import LoadingModuleList from "../../Components/LoadingModuleList";
import VideoCardRenderer from "../../Components/VideoCardRenderer";
import CollectionSearchResultsVideoTranscriptList from "./../List/CollectionSearchResultsVideoTranscriptList";
import { API_URL } from "../../const";
import "./certification.css";
import CertificationInfoCard from "./CertificationInfoCard";
import CollectionBreadcrumb from "../CollectionBreadcrumb";
import { getCollectionNetPrice, hasPermanentAccess } from "../../helper";
import LoadingCertificationInfoCard from "../../Components/LoadingCertificationInfoCard";

const { Paragraph, Text } = Typography;

class PremiumCollectionCertificationDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchValue: "",
            searchResults: null,
            moduleIndexOfSelectedVideo: null,
            videoIndex: null,
            selectedVideoModule: null,
            selectedVideoFromSearchResults: null,
            searchLoading: false,
            unlistedKey: "",
            pageTrackId: null
        };
    }

    componentDidMount() {
        //this.props.fetchCollection(this.props.match.params.collectionId)
        let query = new URLSearchParams(this.props.location.search);

        this.setState({ unlistedKey: query.get("key") });
        this.props.fetchSingleCollection(
            this.props.match.params.collectionId,
            query.get("key"),
            query.get("promotion_code"),
            this.collectionNotFound
        );
        this.props.fetchSingleCollectionAccess(
            this.props.match.params.collectionId
        );
        this.props.fetchCollectionCertificationDetails(
            this.props.match.params.collectionId
        );
        if (this.props.isAuthenticated) {
            this.props.fetchCollectionDetailScheduledLicenses(
                this.props.match.params.collectionId,
                this.props.currentUser.id
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.isAuthenticated &&
            this.props.isAuthenticated &&
            this.props.currentUser.isContentCreator
        ) {
            this.props.fetchCollectionDetailScheduledLicenses(
                this.props.match.params.collectionId,
                this.props.currentUser.id
            );
        }
    }

    componentWillUnmount() {
        message.destroy();
    }

    collectionNotFound = () => {
        this.props.history.push("/404");
    };

    hasValidAccess = (module) => {
        if (this.props.moduleAccess[module.id]) {
            if (this.props.moduleAccess[module.id].accessType === "permanent") {
                return true;
            } else if (this.props.moduleAccess[module.id] === "temporary") {
                let currentDate = moment(new Date());
                let openDate = moment(
                    new Date(this.props.moduleAccess[module.id].accessFrom)
                ).startOf("day");
                let closeDate = moment(
                    new Date(this.props.moduleAccess[module.id].accessUntil)
                ).endOf("day");

                if (currentDate >= openDate && currentDate < closeDate) {
                    return true;
                }
            }
        }
        return false;
    };

    calculateCollectionNetPrice = () => {
        return getCollectionNetPrice(
            this.props.collection,
            this.props.moduleAccess,
            this.props.isAuthenticated
        );
    };

    hasAccessToEntireCollection = () => {
        if (!this.props.isAuthenticated) {
            return false;
        }

        let result = true;

        this.props.collection.modules.forEach((module) => {
            if (!hasPermanentAccess(module, this.props.moduleAccess)) {
                result = false;
            }

            module.licensedModules.forEach((licensedModule) => {
                if (
                    !hasPermanentAccess(licensedModule, this.props.moduleAccess)
                ) {
                    result = false;
                }
            });
        });
        return result;
    };

    handleOnSearchChange = (value) => {
        if (value.length > 0) {
            this.setState({
                searchValue: value,
                searchResults: null,
                selectedVideoFromSearchResults: null,
                searchLoading: true
            });
            fetch(
                API_URL +
                    `/api/v1/collections/${this.props.collection.id}/search/`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        searchValue: value
                    })
                }
            )
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        throw new Error(response.status);
                    }
                })
                .then((data) => {
                    console.log(data);
                    let selectedVideoData = data["results"].length
                        ? data["results"][0]
                        : null;

                    this.setState({
                        searchResults: data["results"],
                        selectedVideoFromSearchResults: selectedVideoData.video,
                        moduleIndexOfSelectedVideo:
                            selectedVideoData.module.moduleIndex + 1,
                        videoIndex: selectedVideoData.order + 1,
                        searchLoading: false,
                        selectedVideoModule: selectedVideoData.module
                    });
                })
                .catch((error) => {
                    this.setState({ searchLoading: false });
                    if (parseInt(error.message) == 401) {
                    } else {
                        console.log(error);
                    }
                });
        } else {
            this.setState({
                searchValue: value,
                searchResults: null,
                selectedVideoFromSearchResults: null,
                selectedVideoModule: null,
                videoIndex: null
            });
        }
    };

    // onEvaluationConfirm() {
    //     this.props.evaluateCollectionCertification(this.props.collection.id);
    // }

    ResultList = () => {
        if (this.state.searchLoading) {
            return (
                <div>
                    <Row gutter={[16, 16]}>
                        {[0, 0, 0, 0].map((video, index) => {
                            return (
                                <Col xs={24} sm={12} md={8} lg={6} key={index}>
                                    <div className="loading-module-list-module-video">
                                        <div className="loading-module-list-module-video-image"></div>
                                        <div className="loading-module-list-module-video-title">
                                            <Skeleton
                                                loading={true}
                                                active
                                                paragraph={false}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                    <br />
                    <br />
                    <Skeleton
                        title={{ width: 400 }}
                        loading={true}
                        active
                        paragraph={false}
                    />
                    <Skeleton loading={true} active paragraph={false} />
                    <Skeleton loading={true} active paragraph={false} />
                    <Skeleton loading={true} active paragraph={false} />
                    <Skeleton loading={true} active paragraph={false} />
                    <Skeleton loading={true} active paragraph={false} />
                </div>
            );
        } else if (
            this.state.searchResults &&
            this.state.searchResults.length
        ) {
            return (
                <>
                    <Paragraph>
                        <Text strong>
                            Search Results Found:{" "}
                            {this.state.searchResults.length} videos
                        </Text>
                        <br />
                        <Text type="secondary">
                            Hover over video to view occurrences of keyword in
                            the transcript. Click <CloseCircleFilled /> in
                            search box to return to course
                        </Text>
                    </Paragraph>
                    <List
                        grid={{ gutter: 16, column: 4 }}
                        pagination={{
                            pageSize: 4
                        }}
                        dataSource={this.state.searchResults}
                        renderItem={(videoResult) => {
                            return (
                                <List.Item>
                                    <div
                                        className={
                                            videoResult.video.id ===
                                            this.state
                                                .selectedVideoFromSearchResults
                                                .id
                                                ? "collection-search-selected-video"
                                                : ""
                                        }
                                        onMouseEnter={() =>
                                            this.setState({
                                                selectedVideoFromSearchResults:
                                                    videoResult.video,
                                                selectedVideoModule:
                                                    videoResult.module,
                                                moduleIndexOfSelectedVideo:
                                                    videoResult.module
                                                        .moduleIndex + 1,
                                                videoIndex:
                                                    videoResult.order + 1
                                            })
                                        }>
                                        <VideoCardRenderer
                                            collection={this.props.collection}
                                            collectionId={
                                                this.props.collection.id
                                            }
                                            isAuthenticated={
                                                this.props.isAuthenticated
                                            }
                                            mode={"purchase"}
                                            module={videoResult.module}
                                            moduleId={videoResult.module.id}
                                            moduleIndex={
                                                videoResult.module.moduleIndex
                                            }
                                            moduleName={videoResult.module.name}
                                            price={videoResult.module.price}
                                            video={videoResult.video}
                                            index={videoResult.order}
                                            moduleAccess={
                                                this.props.moduleAccess
                                            }
                                            unlistedKey={this.state.unlistedKey}
                                        />
                                    </div>
                                </List.Item>
                            );
                        }}
                    />

                    <br />
                    <Paragraph style={{ minHeight: "100vh" }}>
                        <CollectionSearchResultsVideoTranscriptList
                            collectionId={this.props.collection.id}
                            unlistedKey={this.state.unlistedKey}
                            moduleIndex={this.state.moduleIndexOfSelectedVideo}
                            video={this.state.selectedVideoFromSearchResults}
                            searchValue={this.state.searchValue}
                            videoIndex={this.state.videoIndex}
                            isAuthenticated={this.props.isAuthenticated}
                            moduleAccess={this.props.moduleAccess}
                            selectedVideoModule={this.state.selectedVideoModule}
                        />
                    </Paragraph>
                </>
            );
        } else {
            return (
                <Paragraph>
                    <Text strong>
                        No search results found for keyword{" "}
                        <span className="collection-search-keyword-highlight">
                            "{this.state.searchValue}"
                        </span>
                    </Text>
                    <br />
                </Paragraph>
            );
        }
    };

    render() {
        if (
            !this.props.collection ||
            this.props.collection.id != this.props.match.params.collectionId
        )
            return (
                <div id="premium-collection-mid-section">
                    <br />
                    <LoadingCertificationInfoCard />
                    <LoadingModuleList />
                </div>
            );

        return (
            <div id="premium-collection-mid-section">
                {this.props.collection.certification === null ? (
                    <p className="no-certificate-message">
                        NO CERTIFICATION EXISTS FOR THIS COURSE
                    </p>
                ) : (
                    <>
                        {/* Breadcrumb */}
                        <CollectionBreadcrumb
                            collection={this.props.collection}
                            categories={this.props.collectionCategories}
                            isCertificationPage={true}
                        />

                        <div id="collection-detail">
                            {/* Certification Info */}
                            <CertificationInfoCard
                                certificateInfo={this.props.certificateInfo}
                                collectionLoadingStatus={
                                    this.props.collectionLoadingStatus
                                }
                                loadingAuthenticatedUser={
                                    this.props.fetchingUserStatus ===
                                        "NOT LOADED" ||
                                    this.props.fetchingUserStatus === "LOADING"
                                }
                                collectionNetPrice={this.calculateCollectionNetPrice()}
                                currentUser={this.props.currentUser}
                                hasAccessToEntireCollection={this.hasAccessToEntireCollection()}
                                collection={this.props.collection}
                                isAuthenticated={this.props.isAuthenticated}
                            />

                            {/* Collection Module List */}
                            <CollectionModulesList
                                collection={this.props.collection}
                                isAuthenticated={this.props.isAuthenticated}
                                unlistedKey={this.state.unlistedKey}
                                urlParams={this.props.match.params}
                                moduleAccess={this.props.moduleAccess}
                                certificationView={true}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        collection: state.collectionReducer.singleCollection,
        collectionLoadingStatus:
            state.collectionReducer.fetchSingleCollectionStatus,
        collectionCategories: state.collectionReducer.collectionCategories,
        currentUser: state.userReducer.user,
        isAuthenticated: state.userReducer.isAuthenticated,
        fetchingUserStatus: state.userReducer.fetchingUserStatus,
        moduleAccess: state.collectionReducer.singleCollectionAccess,
        certificateInfo: state.collectionCertificationReducer
    };
};
export default connect(mapStateToProps, {
    fetchSingleCollection,
    fetchSingleCollectionAccess,
    fetchCollectionDetailScheduledLicenses,
    fetchCollectionCertificationDetails,
    evaluateCollectionCertification,
    requestCollectionCertification
})(PremiumCollectionCertificationDetail);
