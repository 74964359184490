import { useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Image } from "antd";

import Cupoints from "./Cupoints";
import CollectionThumbnailPreviewer from "./CollectionThumbnailPreviewer";

const { Text } = Typography;

// Collection Small Collection Card
const CollectionCard = ({
    collection,
    redirectToCertificatePage = false,
    imagePreviewHeight = "200px",
    className = ""
}) => {
    const getImageUrl = (parsedCollection) => {
        if (
            redirectToCertificatePage &&
            parsedCollection?.certification?.image
        ) {
            return collection.certification.image;
        }

        return parsedCollection.coverImage.image;
    };
    return (
        <div className={`mini-collection-card border  ${className}`}>
            <div
                className={`mini-collection-card-image-bio`}
                style={{ height: imagePreviewHeight }}>
                <Link
                    to={
                        redirectToCertificatePage && collection.certification
                            ? `/collections/${collection.id}/certification/`
                            : `/collections/${collection.id}`
                    }>
                    <CollectionThumbnailPreviewer
                        collection={collection}
                        videoType={
                            redirectToCertificatePage
                                ? collection?.certification?.promotionVideoType
                                : collection?.introVideoType
                        }
                        videoUrl={
                            redirectToCertificatePage
                                ? collection?.certification?.promotionVideoUrl
                                : collection?.introVideoUrl
                        }
                        thumbnail={
                            <Image
                                src={getImageUrl(collection)}
                                preview={false}
                                width={"100%"}
                                style={{ minHeight: imagePreviewHeight }}
                                className="mini-collection-card-image"
                            />
                        }
                    />
                </Link>
            </div>
            <div className="mini-collection-card-text">
                <h3 className="title">{collection.name}</h3>
                <p>
                    <Text type="secondary">
                        By{" "}
                        <Link
                            to={`/creators/${collection.primaryAuthor.id}`}>{`${collection.primaryAuthor.firstName} ${collection.primaryAuthor.lastName}`}</Link>
                    </Text>
                    <Text type="secondary">
                        {collection.coAuthors.map((coAuthor) => (
                            <Text key={coAuthor.id}>
                                <Link
                                    to={`/creators/${coAuthor.id}`}>{`, ${coAuthor.firstName} ${coAuthor.lastName}`}</Link>
                            </Text>
                        ))}
                    </Text>
                </p>
            </div>
        </div>
    );
};

export default CollectionCard;
