import { Button, Carousel, Image, Space, Typography } from "antd";
import StructureImage from "./structure.png";
import FeedbackImage from "./feedback.png";
import WatchVideoImage from "./watchvideo.png";
import ShareImage from "./share.png";
import { useState } from "react";
import InterdisciplinaryApproach from "./interdisciplinaryapproach.png";
import AnalysisOfAlgorithms from "./analysisofalgorithms.png";
import Algorithms from "./algorithms.png";
import { Link } from "react-router-dom";
import { API_URL } from "./const";
import { getSignUpUrl } from "./utils";

const { Text } = Typography;

const CreatorIntroPage = () => {
    const [collectionBackgroundColor, setCollectionBackgroundColor] = useState(
        "home-collection-one"
    );

    const beforeChange = (from, to) => {
        if (to == 0) {
            setCollectionBackgroundColor("home-collection-one");
        } else if (to == 1) {
            setCollectionBackgroundColor("home-collection-two");
        } else if (to == 2) {
            setCollectionBackgroundColor("home-collection-three");
        }
    };

    return (
        <div>
            <div className="creator-intro-section">
                <div className="creator-intro-hook">
                    <h1>
                        72% of students find curated videos as a necessary
                        resource for course learning *
                    </h1>

                    <p>
                        Personalized and structured video collections,
                        transcripts to help special needs students, and
                        interactive learning for every student in class.
                    </p>
                    <Space>
                        <Button type="primary">
                            <Link to="/walkthrough/create-collection/">
                                See a Demo
                            </Link>
                        </Button>
                    </Space>

                    <p id="creator-intro-footnote">
                        <span>*</span>{" "}
                        <a
                            href="https://www.linkedin.com/pulse/use-pre-curated-videos-support-in-person-learning-cuvids-inc"
                            target="_blank"
                            rel="noopener noreferrer">
                            Read more
                        </a>
                    </p>
                </div>
                <div></div>
            </div>

            <div className="creator-intro-first">
                <div className="creator-benefits-text creator-benefits-one">
                    Easy to use, long-term education solution that will provide
                    compounding benefits to you and your students
                </div>
                <div className="creator-benefits-text creator-benefits-two">
                    Utilize transcripts and interactive quizzes to maximize your
                    student engagement
                </div>
                <div className="creator-benefits-text creator-benefits-three">
                    Create collections to monetize to students all over the
                    world and passively earn from your work
                </div>
            </div>

            <div className="creator-intro-second">
                <div className="creator-explanations-row-one">
                    <div className="creator-explanations-card">
                        <h3>Organized videos in a collection</h3>
                        <p>
                            A collection is a set of grouped videos you can
                            create in your own way - use pre-recorded videos,
                            youtube playlists, or record new videos to make a
                            personalized collection.
                        </p>
                    </div>
                    <Image src={StructureImage} preview={false} />
                </div>
                <div className="creator-explanations-row-two">
                    <Image src={WatchVideoImage} preview={false} />
                    <div className="creator-explanations-card">
                        <h3>Interactive and Engaging</h3>
                        <p>
                            Auto generated transcripts using Machine Learning to
                            help special needs students with learning. Add or
                            import your own quiz questions throughout the videos
                            to make content more engaging.
                        </p>
                    </div>
                </div>
                <div className="creator-explanations-row-three">
                    <div className="creator-explanations-card">
                        <h3>Easy to Share</h3>
                        <p>
                            1) Share the link to your collection. 2) Grant your
                            students free access for a limited time and
                            formulate a roster by creating a class code. 3) For
                            long term access, students can purchase content with
                            cupoints.
                        </p>
                    </div>
                    <Image src={ShareImage} preview={false} />
                </div>
                <div className="creator-explanations-row-four">
                    <Image src={FeedbackImage} preview={false} />
                    <div className="creator-explanations-card">
                        <h3>Informative Feedback</h3>
                        <p>
                            Using the Cubits Dashboard - see where students are
                            struggling, what the engagement is like based on
                            graphs and analytics, and actively tailor your
                            course to best fit your class needs.
                        </p>
                    </div>
                </div>
            </div>

            <section id="creator-create-collections-steps">
                <h3>Create a collection in 3 easy steps</h3>
                <ol>
                    <li className="creator-create-collections-step-one">
                        <p className="creator-create-collections-step-label">
                            1
                        </p>
                        <p className="creator-create-collections-step-text">
                            Add videos from YouTube or Video URLs
                        </p>
                    </li>
                    <li className="creator-create-collections-step-two">
                        <p className="creator-create-collections-step-label">
                            2
                        </p>
                        <p className="creator-create-collections-step-text">
                            Group your videos into modules
                        </p>
                    </li>
                    <li className="creator-create-collections-step-three">
                        <p className="creator-create-collections-step-label">
                            3
                        </p>
                        <p className="creator-create-collections-step-text">
                            Share your collection page with others
                        </p>
                    </li>
                </ol>
            </section>

            <section
                id="home-collections"
                className={collectionBackgroundColor}>
                <div>
                    <div id="home-collections-section-title-container">
                        <h2 id="home-collections-section-title">Collections</h2>
                        <Link
                            to="/collections"
                            id="home-collections-section-view-all">
                            View All
                        </Link>
                    </div>
                    <div id="home-collections-carousel">
                        <Carousel
                            autoplay
                            dotPosition="right"
                            beforeChange={beforeChange}>
                            <div>
                                <div className="home-collection">
                                    <div className="home-collection-image-bio">
                                        <Image
                                            src={InterdisciplinaryApproach}
                                            preview={false}
                                            className="home-collection-image"
                                        />
                                        <div className="home-collection-bio">
                                            <p>
                                                By{" "}
                                                <a
                                                    href="https://www.cubits.ai/creators/382"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    Robert Sedgewick
                                                </a>{" "}
                                                and{" "}
                                                <a
                                                    href="https://www.cubits.ai/creators/385"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    Kevin Wayne
                                                </a>
                                            </p>
                                            <Link
                                                to="/collections/5/"
                                                className="home-collection-view-btn">
                                                View Full Collection
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="home-collection-text">
                                        <h3>
                                            Computer Science: An
                                            Interdisciplinary Approach
                                        </h3>

                                        <p>
                                            This video course is a complete
                                            introduction to computer science in
                                            the context of scientific,
                                            engineering, and commercial
                                            applications. The goal of the course
                                            is to teach basic principles and
                                            practical issues, while at the same
                                            time preparing students to use
                                            computers effectively for
                                            applications in computer science,
                                            physics, biology, chemistry,
                                            engineering, and other disciplines.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="home-collection">
                                    <div className="home-collection-image-bio">
                                        <Image
                                            src={AnalysisOfAlgorithms}
                                            preview={false}
                                            className="home-collection-image"
                                        />
                                        <div className="home-collection-bio">
                                            <p>
                                                By{" "}
                                                <a
                                                    href="https://www.cubits.ai/creators/382"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    Robert Sedgewick
                                                </a>
                                            </p>
                                            <Link
                                                to="/collections/8/"
                                                className="home-collection-view-btn">
                                                View Full Collection
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="home-collection-text">
                                        <h3>Analysis of Algorithms</h3>

                                        <p>
                                            People who analyze algorithms have
                                            double happiness. First of all they
                                            experience the sheer beauty of
                                            elegant mathematical patterns that
                                            surround elegant computational
                                            procedures. Then they receive a
                                            practical payoff when their theories
                                            make it possible to get other jobs
                                            done more quickly and more
                                            economically. D. E. Knuth
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="home-collection">
                                    <div className="home-collection-image-bio">
                                        <Image
                                            src={Algorithms}
                                            preview={false}
                                            className="home-collection-image"
                                        />
                                        <div className="home-collection-bio">
                                            <p>
                                                By{" "}
                                                <a
                                                    href="https://www.cubits.ai/creators/382"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    Robert Sedgewick
                                                </a>{" "}
                                                and{" "}
                                                <a
                                                    href="https://www.cubits.ai/creators/385"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                    Kevin Wayne
                                                </a>
                                            </p>
                                            <Link
                                                to="/collections/25/"
                                                className="home-collection-view-btn">
                                                View Full Collection
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="home-collection-text">
                                        <h3>Algorithms</h3>

                                        <p>
                                            This course surveys the most
                                            important algorithms and data
                                            structures in use on computers
                                            today. Particular emphasis is given
                                            to algorithms for sorting,
                                            searching, and string processing.
                                            Fundamental algorithms in a number
                                            of other areas are covered as well,
                                            including geometric and graph
                                            algorithms. The course will
                                            concentrate on developing
                                            implementations, understanding their
                                            performance characteristics, and
                                            estimating their potential
                                            effectiveness in applications.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </section>

            <div className="creator-intro-fifth">
                <div className="creator-faq-section">
                    <h1>FAQ</h1>

                    <div id="creator-faq-card-list">
                        <div className="creator-faq-card">
                            <h3>What are the major benefits to instructors?</h3>
                            <p>
                                Student motivation, engagement and
                                accountability are some of the most challenging
                                problems in education. CUbits was designed to
                                address all of that and more. Motivate students
                                to learn beyond lecture using curated videos.
                                Engage students with interactivity and real-time
                                dashboards. Make students accountable through
                                various notification and scheduling tools.
                                Tailor instructions and intervene with
                                struggling students early. Once you build a
                                collection once, you can continue to improve and
                                reuse from semester to semester. See{" "}
                                <Text italic>more instructor benefits</Text> at
                                -{" "}
                                <a
                                    href="https://bit.ly/3q346du"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    https://bit.ly/3q346du
                                </a>
                            </p>
                        </div>

                        <div className="creator-faq-card">
                            <h3>
                                I am an instructor/author. Can I evaluate
                                platform and premium videos for free?
                            </h3>
                            <p>
                                Sure. Just send email to: demo@cubits.ai to
                                receive instructions on how to get access to
                                complementary premium videos. If you want to
                                build your own collection, create an account{" "}
                                <a
                                    href={`${getSignUpUrl()}`}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {API_URL}/accounts/signup/
                                </a>{" "}
                                and get started for free. See{" "}
                                <Text italic>how-to-build-my-video-course</Text>{" "}
                                -{" "}
                                <a
                                    href="https://bit.ly/3q346du"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    https://bit.ly/3q346du
                                </a>
                            </p>
                        </div>

                        <div className="creator-faq-card">
                            <h3>
                                I do not have time to record my own videos, are
                                there premium videos already available on
                                CUbits?
                            </h3>
                            <p>
                                Yes. We have prominent author and publisher
                                built CS video collections. You can find and
                                adopt them at{" "}
                                <a
                                    href="https://www.cubits.ai/collections/"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    https://www.cubits.ai/collections/
                                </a>
                                . The cost is as low as $2 per module (students
                                can purchase as many modules as needed using a
                                micro-payment system). We have exclusive
                                partnerships with Princeton University Press and
                                other publishers to bring more curated videos to
                                the market soon.
                            </p>
                        </div>

                        <div className="creator-faq-card">
                            <h3>
                                I am currently teaching a flipped class or
                                interested in teaching one. What are the
                                benefits of using CUbits platform for a flipped
                                class?
                            </h3>
                            <p>
                                Typical flipped courses just assign videos to
                                students but lack tools and techniques to keep
                                students accountable and engaged with videos. We
                                developed this technology to address some of
                                those challenges that we had at our courses at
                                Princeton. If you are teaching a flipped or
                                using an alternate teaching method, benefits of
                                this platform are enormous.
                            </p>
                        </div>

                        <div className="creator-faq-card">
                            <h3>
                                I have my own pre-recorded videos. How do I
                                curate them for my students?
                            </h3>
                            <p>
                                The process is simple. You host videos as a
                                YouTube or Vimeo playlist, integrate them to
                                CUbits through simple UI (no video uploads
                                necessary), build your own curated video course
                                in minutes. See{" "}
                                <Text italic>how-to-build-my-video-course</Text>{" "}
                                user manual -{" "}
                                <a
                                    href="https://bit.ly/3q346du"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    https://bit.ly/3q346du
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="creator-intro-sixth">
                <h3 id="creator-end-hook">Ready to try it out yourself?</h3>
                <Space>
                    <Button type="primary">
                        <a href={`${getSignUpUrl()}`}>Get started for free</a>
                    </Button>
                </Space>
            </div>
        </div>
    );
};

export default CreatorIntroPage;
