import { Component, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    fetchCreatorPublicCertificationCourses,
    fetchCreatorPublicProfile
} from "../actions/creator";
import {
    Col,
    PageHeader,
    Row,
    Space,
    Typography,
    Tabs,
    Card,
    List,
    Spin
} from "antd";
import CreatorProfilePicture from "./CreatorProfilePicture";
import CertificateCollectionCard from "../Components/CertificateCollectionCard";
import CertificateCollectionCardLoading from "../Components/CertificateCollectionCardLoading";
import CollectionCard from "../Components/CollectionCard";

const { Link, Paragraph, Text, Title } = Typography;
const { TabPane } = Tabs;

// Loading cards section
const LoadingSection = () => {
    return (
        <List
            grid={{
                gutter: 8,
                xs: 1,
                sm: 2,
                md: 3,
                lg: 3,
                xl: 3,
                xxl: 3
            }}
            dataSource={[...Array(6).keys()]}
            renderItem={() => (
                <List.Item>
                    <div className="border">
                        <CertificateCollectionCardLoading />
                    </div>
                </List.Item>
            )}
        />
    );
};

const AuthorProfileSection = ({ creatorProfile, loadingStatus }) => {
    if (!creatorProfile) return null;

    const cardStyle = {
        minHeight: "calc(100vh - 12rem)"
    };

    return (
        <>
            <PageHeader
                title={`Back`}
                subTitle={``}
                ghost={false}
                onBack={() => window.history.back()}
                id="author-profile"
                style={cardStyle}>
                <div className="text-center">
                    <CreatorProfilePicture
                        existingCoverImage={creatorProfile.profilePicture}
                        isOwner={false}
                        userId={creatorProfile.id}
                    />
                    <Title
                        level={2}
                        style={{ marginTop: "1.5rem", marginBottom: 0 }}>
                        {creatorProfile.firstName} {creatorProfile.lastName}
                    </Title>
                    <Title
                        level={4}
                        type="secondary"
                        style={{ marginTop: 0, marginBottom: 0 }}>
                        {creatorProfile.username}
                    </Title>

                    {creatorProfile.externalWebsite.length && (
                        <Link
                            href={creatorProfile.externalWebsite}
                            target="_blank">
                            {creatorProfile.externalWebsite}
                        </Link>
                    )}
                    <br />
                    <Text type="secondary">Affiliations</Text>
                    <br />
                    <Space align="start">
                        <Text>
                            {creatorProfile.universities
                                .map((university) => university.name)
                                .join(", ")}
                        </Text>
                    </Space>
                    <br />
                    <br />

                    <Paragraph style={{ padding: "0 1rem" }}>
                        {creatorProfile.bio}
                    </Paragraph>
                </div>
            </PageHeader>
        </>
    );
};

const CertificateCourseList = ({ collections, loadingStatus }) => {
    return (
        <>
            {loadingStatus !== "LOADING" ? (
                <List
                    className="full-height-grid-list"
                    grid={{
                        gutter: 8,
                        xs: 1,
                        sm: 2,
                        md: 3,
                        lg: 3,
                        xl: 3,
                        xxl: 3
                    }}
                    dataSource={collections}
                    renderItem={(collection) => (
                        <List.Item className="list-item-full-height">
                            <CertificateCollectionCard
                                collection={collection}
                            />
                        </List.Item>
                    )}
                />
            ) : (
                <LoadingSection />
            )}
        </>
    );
};

const OtherPublicCourseList = ({ collections, loadingStatus }) => {
    return (
        <>
            {loadingStatus !== "LOADING" ? (
                <>
                    <List
                        className="full-height-grid-list"
                        grid={{
                            gutter: 8,
                            xs: 1,
                            sm: 2,
                            md: 3,
                            lg: 3,
                            xl: 3,
                            xxl: 3
                        }}
                        dataSource={collections}
                        renderItem={(collection) => (
                            <List.Item className="list-item-full-height">
                                <CollectionCard collection={collection} />
                            </List.Item>
                        )}
                    />
                </>
            ) : (
                <LoadingSection />
            )}
        </>
    );
};

const AuthorColectionTabSection = ({ collections, loadingStatus }) => {
    const [certificateCourses, setCertificateCourses] = useState([]);
    const [publicCourses, setPublicCourses] = useState([]);

    const cardStyle = {
        boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.2)",
        minHeight: "calc(100vh - 12rem)"
    };

    useEffect(() => {
        if (loadingStatus === "LOADED" && collections.length > 0) {
            let tempCertificateCourses = [];
            let tempPublicCourses = [];
            collections.forEach((collection) => {
                if (Boolean(collection.certification)) {
                    tempCertificateCourses.push(collection);
                } else {
                    tempPublicCourses.push(collection);
                }
            });
            setCertificateCourses(tempCertificateCourses);
            setPublicCourses(tempPublicCourses);
        }
    }, [collections, loadingStatus]);

    return (
        <Card bordered style={cardStyle}>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Certificate Courses" key="1">
                    <CertificateCourseList
                        collections={certificateCourses}
                        loadingStatus={loadingStatus}
                    />
                </TabPane>
                <TabPane tab="Other Courses" key="2">
                    <OtherPublicCourseList
                        collections={publicCourses}
                        loadingStatus={loadingStatus}
                    />
                </TabPane>
            </Tabs>
        </Card>
    );
};

class AuthorProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleCreatorNotFound = () => {
        this.props.history.push("/404");
    };

    handleCreatorContents = () => {
        this.props.fetchCreatorPublicCertificationCourses(
            this.props.match.params.authorSlug
        );
    };

    componentDidMount() {
        this.props.fetchCreatorPublicProfile(
            this.props.match.params.authorSlug,
            this.handleCreatorContents,
            this.handleCreatorNotFound
        );
    }

    componentDidUpdate(prevProps) {}
    render() {
        const leftColStyle = {
            position: "sticky",
            top: "78px",
            height: "calc(100vh - 12rem)"
        };

        return (
            <div className="centered-container">
                <div className="creator-page-content">
                    {this.props.creatorProfileStatus !== "LOADING" ? (
                        <Row gutter={[16, 0]} style={{ padding: "20px 0" }}>
                            {/* First Column */}
                            <Col
                                xs={24}
                                sm={24}
                                md={8}
                                lg={8}
                                xl={8}
                                style={leftColStyle}>
                                <AuthorProfileSection
                                    creatorProfile={this.props.creatorProfile}
                                    loadingStatus={
                                        this.props.creatorProfileStatus
                                    }
                                />
                            </Col>

                            {/* Second Column */}
                            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                                <AuthorColectionTabSection
                                    collections={
                                        this.props.creatorPublicCollections
                                    }
                                    loadingStatus={
                                        this.props
                                            .creatorPublicCollectionsStatus
                                    }
                                />
                            </Col>
                        </Row>
                    ) : (
                        <div className="loading-authentication">
                            <Spin />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.user,
        creatorProfile: state.creatorReducer.creatorProfile,
        creatorProfileStatus: state.creatorReducer.creatorProfileStatus,
        creatorPublicCollections: state.creatorReducer.creatorPublicCollections,
        creatorPublicCollectionsStatus:
            state.creatorReducer.creatorPublicCollectionsStatus
    };
};
export default connect(mapStateToProps, {
    fetchCreatorPublicProfile,
    fetchCreatorPublicCertificationCourses
})(AuthorProfilePage);
