/******************************************************************
CheckboxGrid.js
Written by Adam Gamba, Summer 2021

Used to display a grid of checkboxes, dashes, or Xs depending on a
student's watched videos
******************************************************************/
import { connect } from "react-redux";
import {
    CheckSquareOutlined,
    MinusSquareOutlined,
    CloseSquareOutlined
} from "@ant-design/icons";
import { Card, Divider, Space, Spin, Table, Tooltip, Typography } from "antd";
import { Link } from "react-router-dom";

const { Text } = Typography;
const CheckboxGrid = (props) => {
    // List of unique module Ids
    const modulesList = [...new Set(props.videoList.map((x) => x.moduleId))];
    const modulesNameList = [
        ...new Set(props.videoList.map((x) => x.moduleName))
    ];

    const columns = [
        {
            title: "Module Name",
            dataIndex: "moduleName",
            key: "moduleName"
        },
        {
            title: "Watch Activity",
            dataIndex: "videos",
            key: "watchActivity",
            render: (videos) => {
                return videos.map((video) => {
                    let icon = null;
                    if (video.percentWatched < 0.25)
                        icon = (
                            <CloseSquareOutlined
                                style={{ color: "red", fontSize: "24px" }}
                            />
                        );
                    else if (video.percentWatched < 0.45) {
                        icon = (
                            <MinusSquareOutlined
                                style={{
                                    color: "orange",
                                    fontSize: "24px"
                                }}
                            />
                        );
                    } else {
                        icon = (
                            <CheckSquareOutlined
                                style={{
                                    color: "green",
                                    fontSize: "24px"
                                }}
                            />
                        );
                    }
                    return (
                        <Tooltip title={video.videoTitle}>
                            {/*<Link
                to={`/dashboard/videos/${video.moduleId}/${video.videoId}`}
              >*/}
                            {icon}
                            {/*</Link>*/}
                        </Tooltip>
                    );
                });
            }
        }
    ];
    const populateData = () => {
        return modulesList.map((moduleId, index) => {
            let thisModule = props.videoList.filter(
                (x) => x.moduleId === moduleId
            );
            return {
                moduleName: modulesNameList[index],
                videos: thisModule.map((video, vIndex) => {
                    // Determine if student has watched this particular
                    // video
                    let match = undefined;
                    let percentWatched = 0;
                    if (props.watchedVideos.hasOwnProperty([moduleId])) {
                        match = props.watchedVideos[moduleId].filter(
                            (x) => video.videoTitle === x.videoTitle
                        );
                        // Old: hasWatched = match.length > 0;
                    }
                    if (match !== undefined && match.length > 0) {
                        match = match[0];
                        // Deals with private/unlisted videos (0 video duration)
                        if (match.videoDuration === 0) {
                            match.videoDuration = match.data.length * 60;
                        }
                        // Amount watched / video duration
                        percentWatched =
                            (match.dataSum * 60) / match.videoDuration;
                    }
                    return {
                        percentWatched: percentWatched,
                        videoTitle: video.videoTitle,
                        key: vIndex
                    };
                }),
                key: index
            };
        });
    };

    if (props.watchedVideos === undefined) {
        return <Spin />;
    } else
        return (
            <>
                <br />
                <Text level={5}>Legend</Text>
                <br />
                <Space>
                    <div className="flex-center" style={{ margin: "5px" }}>
                        <CheckSquareOutlined
                            style={{
                                color: "green",
                                fontSize: "24px"
                            }}
                        />
                        = Fully Watched (50-100%)
                    </div>
                    <div className="flex-center" style={{ margin: "5px" }}>
                        <MinusSquareOutlined
                            style={{
                                color: "orange",
                                fontSize: "24px"
                            }}
                        />
                        = Partially Watched (25-50%)
                    </div>
                    <div className="flex-center" style={{ margin: "5px" }}>
                        <CloseSquareOutlined
                            style={{ color: "red", fontSize: "24px" }}
                        />
                        = Not Watched (0-25%)
                    </div>
                </Space>
                <br />
                <br />
                <Table dataSource={populateData()} columns={columns} />
            </>
        );
};

const mapStateToProps = (state) => {
    return {
        videoList: state.dashboardReducer.videoList,
        videoListStatus: state.dashboardReducer.videoListStatus
    };
};
export default connect(mapStateToProps, {})(CheckboxGrid);
