import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
    Affix,
    Button,
    Dropdown,
    Layout,
    message,
    Menu,
    PageHeader,
    Space,
    Switch
} from "antd";
import {
    clearModuleAccessForVideo,
    fetchModuleAccessForVideo,
    toggleMutedQuizQuestions
} from "../../actions/videoControl";
import {
    clearSelectedModuleAndVideo,
    getQuizQuestions,
    fetchSingleCollection,
    getTranscript,
    getVideoData
} from "../../actions/collection";
import { QuestionCircleOutlined } from "@ant-design/icons";

import VideoPageMenu from "./VideoPageMenu";
import VimeoPlayer from "./Player/VimeoPlayer";
import YouTubePlayer from "./Player/YouTubePlayer";
import QnAContainer from "./Q&A/QnAContainer";
import LoadingVideoPage from "./LoadingVideoPage";

class VideoPage extends Component {
    constructor(props) {
        super(props);
        let affixStatus = localStorage.getItem("videoPageAffix");
        this.state = {
            collapsedMenu:
                localStorage.getItem("collapsedMenu") === null
                    ? true
                    : localStorage.getItem("collapsedMenu") === "true",
            collapsedTranscript: false,
            customStartTime: null,
            openAffixDropdown:
                typeof affixStatus !== "undefined" && affixStatus !== null
                    ? affixStatus === "true"
                    : false,
            selectedQnALevel: "video",
            moduleId: null,
            videoId: null,
            trackPageEvent: false,
            pageTrackId: null
        };
    }
    componentDidMount() {
        // fetch transcript text
        let query = new URLSearchParams(this.props.location.search);
        let startTime = query.get("t");
        if (startTime) {
            this.setState({ customStartTime: parseFloat(startTime) });
        }
        if (!this.props.singleCollectionIsSet) {
            this.props.fetchSingleCollection(
                this.props.match.params.collectionId,
                query.get("key"),
                query.get("promotion_code"),
                this.collectionNotFound
            );
        } else {
            let modulePosition = parseInt(this.props.match.params.moduleIndex);
            let videoPosition = parseInt(this.props.match.params.videoIndex);
            let moduleId, videoId;

            if (
                modulePosition > this.props.singleCollection.modules.length ||
                videoPosition >
                    this.props.singleCollection.modules[modulePosition - 1]
                        .videos.length
            ) {
                // not valid
                this.props.history.push(
                    `/collections/${this.props.match.params.collectionId}/${
                        query.get("key") ? "?key=" + query.get("key") : ""
                    }`
                );
            } else {
                moduleId =
                    this.props.singleCollection.modules[modulePosition - 1].id;
                videoId =
                    this.props.singleCollection.modules[modulePosition - 1]
                        .videos[videoPosition - 1].id;

                this.props.fetchModuleAccessForVideo(
                    this.props.match.params.collectionId,
                    moduleId,
                    videoId
                );
                this.props.getVideoData(
                    this.props.match.params.collectionId,
                    moduleId,
                    videoId
                );
                this.props.getTranscript(videoId);
                this.props.getQuizQuestions(
                    this.props.match.params["collectionId"],
                    moduleId,
                    videoId
                );
                this.setState({
                    moduleId: moduleId,
                    videoId: videoId
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.singleCollectionIsSet !==
                this.props.singleCollectionIsSet &&
                this.props.singleCollectionIsSet) ||
            !this.equalUrlParams(
                prevProps.match.params,
                this.props.match.params
            )
        ) {
            let query = new URLSearchParams(this.props.location.search);
            let modulePosition = parseInt(this.props.match.params.moduleIndex);
            let videoPosition = parseInt(this.props.match.params.videoIndex);
            let moduleId, videoId;

            if (
                modulePosition > this.props.singleCollection.modules.length ||
                videoPosition >
                    this.props.singleCollection.modules[modulePosition - 1]
                        .videos.length
            ) {
                // not valid
                this.props.history.push(
                    `/collections/${this.props.match.params.collectionId}/${
                        query.get("key") ? "?key=" + query.get("key") : ""
                    }`
                );
            } else {
                moduleId =
                    this.props.singleCollection.modules[modulePosition - 1].id;
                videoId =
                    this.props.singleCollection.modules[modulePosition - 1]
                        .videos[videoPosition - 1].id;

                this.props.fetchModuleAccessForVideo(
                    this.props.match.params.collectionId,
                    moduleId,
                    videoId
                );
                this.props.getVideoData(
                    this.props.match.params.collectionId,
                    moduleId,
                    videoId
                );
                this.props.getTranscript(videoId);
                this.props.getQuizQuestions(
                    this.props.match.params["collectionId"],
                    moduleId,
                    videoId
                );
                this.setState({
                    moduleId: moduleId,
                    videoId: videoId
                });

                this.setState({
                    pageTrackId: null,
                    trackPageEvent: true
                });
            }
        }
    }

    componentWillUnmount() {
        this.props.clearSelectedModuleAndVideo();
        this.props.clearModuleAccessForVideo();
    }

    collectionNotFound = () => {
        this.props.history.push("/404");
    };

    equalUrlParams = (prev, current) => {
        return (
            prev["collectionId"] === current["collectionId"] &&
            prev["moduleIndex"] === current["moduleIndex"] &&
            prev["videoIndex"] === current["videoIndex"]
        );
    };

    breadcrumbConfig = () => {
        function itemRender(route, params, routes, paths) {
            const last = routes.indexOf(route) === routes.length - 1;
            return last || route.path == null ? (
                <span>{route.breadcrumbName}</span>
            ) : (
                <Link to={route.path}>{route.breadcrumbName}</Link>
            );
        }
        let query = new URLSearchParams(this.props.location.search);

        return {
            itemRender: itemRender,
            routes: [
                {
                    path: "/",
                    breadcrumbName: "Home"
                },
                {
                    path: "/collections/",
                    breadcrumbName: "Courses"
                },
                {
                    path: `/collections/${this.props.singleCollection.id}/${
                        query.get("key") ? "?key=" + query.get("key") : ""
                    }`,
                    breadcrumbName: this.props.singleCollection.name
                },
                {
                    breadcrumbName: this.props.singleModule.name
                },
                {
                    breadcrumbName: this.props.singleVideo.title
                }
            ]
        };
    };
    toggleCollapsedMenu = () => {
        localStorage.setItem("collapsedMenu", !this.state.collapsedMenu);
        this.setState({
            collapsedMenu: !this.state.collapsedMenu
        });
    };
    toggleCollapsedTranscript = () => {
        this.setState({
            collapsedTranscript: !this.state.collapsedTranscript
        });
    };

    scrollToTopOfQnA = () => {
        let element = document.getElementById(
            "video-page-extra-content-layout"
        );
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    scrollToTopOfVideoPlayer = () => {
        let element = document.getElementById("video-show-page");
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    setQnALevel = (value) => {
        this.setState({
            selectedQnALevel: value
        });
    };

    menu = () => {
        return (
            <Menu>
                <Menu.Item onClick={this.scrollToTopOfVideoPlayer}>
                    Video Player
                </Menu.Item>
                <Menu.ItemGroup title="Q&A for:">
                    <Menu.Item
                        onClick={() => {
                            this.setQnALevel("video");
                            this.scrollToTopOfQnA();
                        }}>
                        Video{" "}
                        <span className="video-page-affix-menu-qna-count">
                            {this.props.videoQnA.length}
                        </span>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => {
                            this.setQnALevel("module");
                            this.scrollToTopOfQnA();
                        }}>
                        Module{" "}
                        <span className="video-page-affix-menu-qna-count">
                            {this.props.moduleQnA.length}
                        </span>
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => {
                            this.setQnALevel("collection");
                            this.scrollToTopOfQnA();
                        }}>
                        Course{" "}
                        <span className="video-page-affix-menu-qna-count">
                            {this.props.collectionQnA.length}
                        </span>
                    </Menu.Item>
                </Menu.ItemGroup>
            </Menu>
        );
    };

    render() {
        if (
            (this.props.fetchingCollections || this.props.fetchingUser) &&
            localStorage.getItem("access") != null
        ) {
            return <LoadingVideoPage />;
        }
        if (
            !this.props.isAuthenticated &&
            !this.props.fetchingUser &&
            localStorage.getItem("access") === null
        ) {
            let query = new URLSearchParams(this.props.location.search);
            message.warning("You must login before accessing this content.");
            return (
                <Redirect
                    to={`/collections/${this.props.match.params.collectionId}/${
                        query.get("key") ? "?key=" + query.get("key") : ""
                    }`}
                />
            );
        }
        if (
            (!this.props.contentDoesNotExist &&
                (this.props.fetchingUser ||
                    this.props.fetchingCollections ||
                    !this.props.singleCollectionIsSet ||
                    !this.props.singleVideoIsSet ||
                    !this.props.singleModuleIsSet)) ||
            this.props.moduleAccessForVideoStatus !== "LOADED"
        ) {
            return <LoadingVideoPage />;
        }
        if (
            this.props.moduleAccessForVideoStatus == "LOADED" &&
            this.props.moduleAccessForVideo.access === "denied"
        ) {
            let query = new URLSearchParams(this.props.location.search);
            let indexOfModule = this.props.singleCollection.modules.findIndex(
                (module) => module.id == this.props.singleModule.id
            );

            message.error(
                "You do not have access to this content. If you would like to gain permanent access, you can purchase the module with cupoints.",
                0
            );
            return (
                <Redirect
                    to={`/collections/${
                        this.props.match.params.collectionId
                    }/modules/${indexOfModule + 1}/${
                        query.get("key") ? "?key=" + query.get("key") : ""
                    }`}
                />
            );
        }
        if (
            this.props.moduleAccessForVideoStatus == "LOADED" &&
            this.props.moduleAccessForVideo.access === "expired"
        ) {
            let query = new URLSearchParams(this.props.location.search);
            let expireDate = new Date(
                this.props.moduleAccessForVideo.data.accessUntil
            );
            expireDate = new Date(
                expireDate.getTime() + expireDate.getTimezoneOffset() * 60000
            );
            expireDate.setHours(23, 59, 59, 999);

            let indexOfModule = this.props.singleCollection.modules.findIndex(
                (module) => module.id == this.props.singleModule.id
            );
            message.error(
                `Your access to this module, granted by your coupon, expired on ${expireDate.toLocaleString()}. If you would like to gain permanent access, you can purchase the module with cupoints.`,
                0
            );
            return (
                <Redirect
                    to={`/collections/${
                        this.props.match.params.collectionId
                    }/modules/${indexOfModule + 1}/${
                        query.get("key") ? "?key=" + query.get("key") : ""
                    }`}
                />
            );
        }

        if (this.props.contentDoesNotExist) {
            return <Redirect to="/404" />;
        }

        return (
            <div id="video-show-page" className="clearfix">
                <PageHeader
                    style={{
                        paddingLeft: "42px"
                    }}
                    ghost={false}
                    onBack={() => window.history.back()}
                    title={this.props.singleVideo.title}
                    breadcrumb={this.breadcrumbConfig()}
                    extra={[
                        <Space align="center" key={1}>
                            <Switch
                                size="small"
                                onChange={this.props.toggleMutedQuizQuestions}
                                checked={this.props.mutedQuizQuestions}
                            />
                            Mute Quiz Questions
                            {/* <Switch
                                size="small"
                                onChange={this.toggleCollapsedMenu}
                                checked={!this.state.collapsedMenu}
                            />
                            {this.state.collapsedMenu
                                ? "Show Menu"
                                : "Hide Menu"} */}
                            <Switch
                                size="small"
                                onChange={this.toggleCollapsedTranscript}
                                checked={!this.state.collapsedTranscript}
                            />
                            {this.state.collapsedTranscript
                                ? "Show Tools"
                                : "Hide Tools"}
                        </Space>
                    ]}></PageHeader>

                <Layout id="video-page-layout">
                    <VideoPageMenu
                        isMenuCollapsed={this.state.collapsedMenu}
                        onToggle={this.toggleCollapsedMenu}
                        unlistedKey={new URLSearchParams(
                            this.props.location.search
                        ).get("key")}
                    />

                    {this.props.singleVideo.videoType === "youtube" ? (
                        <YouTubePlayer
                            collection={this.props.singleCollection}
                            module={this.props.singleModule}
                            video={this.props.singleVideo}
                            customStartTime={this.state.customStartTime}
                            collapsedTranscript={this.state.collapsedTranscript}
                        />
                    ) : (
                        <VimeoPlayer
                            collection={this.props.singleCollection}
                            module={this.props.singleModule}
                            video={this.props.singleVideo}
                            customStartTime={this.state.customStartTime}
                            collapsedTranscript={this.state.collapsedTranscript}
                        />
                    )}
                </Layout>

                <Layout id="video-page-extra-content-layout">
                    <QnAContainer
                        collectionId={this.props.match.params.collectionId}
                        moduleId={this.state.moduleId}
                        videoId={this.state.videoId}
                        selectedQnALevel={this.state.selectedQnALevel}
                        setSelectedQnALevel={this.setQnALevel}
                    />
                </Layout>
                <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
                    <Dropdown
                        overlay={this.menu}
                        placement="topLeft"
                        visible={this.state.openAffixDropdown}
                        getPopupContainer={(trigger) => trigger.parentElement}>
                        <Button
                            type="primary"
                            shape="circle"
                            onClick={() => {
                                this.setState({
                                    openAffixDropdown:
                                        !this.state.openAffixDropdown
                                });
                                localStorage.setItem(
                                    "videoPageAffix",
                                    !this.state.openAffixDropdown
                                );
                            }}>
                            <QuestionCircleOutlined />
                        </Button>
                    </Dropdown>
                </Affix>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        contentDoesNotExist: state.collectionReducer.contentDoesNotExist,
        collectionQnA: state.collectionReducer.collectionQnA,
        moduleQnA: state.collectionReducer.moduleQnA,
        videoQnA: state.collectionReducer.videoQnA,
        fetchingUser: state.userReducer.fetchingUser,
        isAuthenticated: state.userReducer.isAuthenticated,
        singleCollection: state.collectionReducer.singleCollection,
        singleModule: state.collectionReducer.singleModule,
        singleVideo: state.collectionReducer.singleVideo,
        singleCollectionIsSet: state.collectionReducer.singleCollectionIsSet,
        singleModuleIsSet: state.collectionReducer.singleModuleIsSet,
        singleVideoIsSet: state.collectionReducer.singleVideoIsSet,
        fetchingCollections: state.collectionReducer.fetchingCollections,
        mutedQuizQuestions: state.videoControlReducer.mutedQuizQuestions,
        moduleAccessForVideo: state.videoControlReducer.moduleAccessForVideo,
        moduleAccessForVideoStatus:
            state.videoControlReducer.moduleAccessForVideoStatus
    };
};

export default connect(mapStateToProps, {
    clearModuleAccessForVideo,
    clearSelectedModuleAndVideo,
    fetchModuleAccessForVideo,
    fetchSingleCollection,
    getTranscript,
    getQuizQuestions,
    toggleMutedQuizQuestions,
    getVideoData
})(VideoPage);
