// actions for rendering graphs
import moment from "moment";
import { message } from "antd";
import { getNewAccessToken } from "./user.js";
import { API_URL } from "../const.js";

// sets selected class coupon
export const setSelectedCoupon = (couponData) => {
    return {
        type: "SET_SELECTED_COUPON",
        couponData
    };
};

export const setCollection = (collectionData) => {
    return {
        type: "SET_COLLECTION",
        collectionData
    };
};

// sets scheduled accesses, called by getCouponData
export const setScheduledAccesses = (scheduledAccesses) => {
    return {
        type: "SET_SCHEDULED_ACCESSES",
        scheduledAccesses
    };
};

// sets class roster, called by getCouponData
export const setRoster = (roster) => {
    return {
        type: "SET_ROSTER",
        roster
    };
};

export const setVideoList = (collectionData) => {
    return {
        type: "SET_VIDEO_LIST",
        collectionData
    };
};

export const setVideoListStatus = (status) => {
    return {
        type: "SET_VIDEO_LIST_STATUS",
        status
    };
};

export const setHomePageVideoActivityData = (data) => {
    return {
        type: "SET_HOME_PAGE_VIDEO_ACTIVITY_DATA",
        data
    };
};

export const setHomePageVideoActivityDataStatus = (status) => {
    return {
        type: "SET_HOME_PAGE_VIDEO_ACTIVITY_DATA_STATUS",
        status
    };
};

export const setHomePageRosterActivityData = (data) => {
    return {
        type: "SET_HOME_PAGE_ROSTER_ACTIVITY_DATA",
        data
    };
};

export const setHomePageRosterActivityDataStatus = (status) => {
    return {
        type: "SET_HOME_PAGE_ROSTER_ACTIVITY_DATA_STATUS",
        status
    };
};

// handles fetching data for a specific coupon
export const getCouponData = (userID, coupon) => {
    return (dispatch) => {
        // Get data for scheduled accesses (videos)
        let url =
            API_URL +
            `/api/v1/users/${userID}/scheduled-licenses/${coupon.id}/`;
        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access")
            }
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(setScheduledAccesses(data.scheduledAccesses));
                dispatch(
                    fetchCollectionData(
                        data.scheduledAccesses[0].collection,
                        data.scheduledAccesses[0].unlistedKey
                    )
                );
                delete data["scheduledAccesses"];
            })
            .catch((error) => console.log(error));

        // Get data for roster
        // Temporary - class dates is a 2 year period centered on today
        let couponCode = coupon.code;
        let classStartDate = moment(
            new Date().setDate(new Date().getDate() - 365)
        ).toISOString();
        let classEndDate = moment(
            new Date().setDate(new Date().getDate() + 365)
        ).toISOString();
        url =
            API_URL +
            `/api/v1/dashboard/videowatchdata/home-page-roster-activity/?coupon=${couponCode}`;

        fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access")
            }
        })
            .then((response) => response.json())
            .then((data) => {
                dispatch(setRoster(data));
                delete data["scheduledAccesses"];
            })
            .catch((error) => console.log(error));
    };
};

export const fetchCollectionData = (collectionId, unlistedKey) => {
    return (dispatch) => {
        dispatch(setVideoListStatus("LOADING"));
        let url = API_URL + `/api/v1/collections/${collectionId}/`;
        if (unlistedKey != null) {
            url += `?key=${unlistedKey}`;
        }
        return fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access")
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setCollection(data));
                dispatch(setVideoList(data));
                dispatch(setVideoListStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    return dispatch(
                        getNewAccessToken(() =>
                            fetchCollectionData(collectionId, unlistedKey)
                        )
                    );
                } else {
                    dispatch(setVideoListStatus("FAILED"));
                    return message.error("Something went wrong.");
                }
            });
    };
};

export const fetchHomePageVideoActivity = (
    startDate,
    endDate,
    couponCode,
    formatDataFunc
) => {
    return (dispatch) => {
        dispatch(setHomePageVideoActivityDataStatus("LOADING"));
        let url =
            API_URL +
            `/api/v1/dashboard/videowatchdata/home-page-video-activity/?after=${startDate}&before=${endDate}&coupon=${couponCode}`;
        return fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access")
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setHomePageVideoActivityData(data));
                formatDataFunc(data);
                dispatch(setHomePageVideoActivityDataStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    return dispatch(
                        getNewAccessToken(() =>
                            fetchHomePageVideoActivity(
                                startDate,
                                endDate,
                                couponCode
                            )
                        )
                    );
                } else {
                    dispatch(setHomePageVideoActivityDataStatus("FAILED"));
                    console.log(error);
                    return message.error("Something went wrong.");
                }
            });
    };
};

export const fetchHomePageRosterActivity = (
    startDate,
    endDate,
    couponCode,
    formatDataFunc,
    setZeroRoster
) => {
    return (dispatch) => {
        dispatch(setHomePageRosterActivityDataStatus("LOADING"));
        let url =
            API_URL +
            `/api/v1/dashboard/videowatchdata/home-page-roster-activity/?after=${startDate}&before=${endDate}&coupon=${couponCode}`;
        return fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access")
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                setZeroRoster(data.filter((student) => student.hours === 0));

                formatDataFunc(data);
                dispatch(setHomePageRosterActivityDataStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    return dispatch(
                        getNewAccessToken(() =>
                            fetchHomePageRosterActivity(
                                startDate,
                                endDate,
                                couponCode,
                                formatDataFunc
                            )
                        )
                    );
                } else {
                    dispatch(setHomePageRosterActivityDataStatus("FAILED"));
                    return message.error("Something went wrong.");
                }
            });
    };
};

export const fetchIndividualStudentActivity = (
    startDate,
    endDate,
    studentUsername,
    couponCode,
    setActivityData
) => {
    return (dispatch) => {
        let url =
            API_URL +
            `/api/v1/dashboard/videowatchdata/individual-student-video-activity/?coupon=${couponCode}&username=${studentUsername}&after=${startDate}&before=${endDate}`;

        return fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access")
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                let formattedVideos = {};
                // Returns maximum video duration from all data (used for
                // scaling purposes))
                let maximumVideoDuration = Object.values(data)
                    .map((x) => x.videoDuration)
                    .reduce((a, b) => {
                        return Math.max(a, b);
                    });

                for (let video in data) {
                    // Example videoObj: {videoTitle: "Conditionals: The IF
                    // Statement", moduleId: 8, collectionId: 5, data: Array(26)}

                    let videoObj = data[video];
                    videoObj.dataSum = videoObj.data.reduce((a, b) => a + b, 0);

                    // Format videos array into object, with keys being module IDs
                    // and values being an array of videos in that module
                    let moduleId = videoObj.moduleId;
                    if (formattedVideos.hasOwnProperty(moduleId)) {
                        formattedVideos[moduleId].push(videoObj);
                    } else {
                        formattedVideos[moduleId] = [videoObj];
                    }
                }
                setActivityData(formattedVideos, maximumVideoDuration);
            })
            .catch((error) => console.log(error));
    };
};

export const fetchVideoRententionData = (
    collectionId,
    moduleId,
    videoId,
    startDate,
    endDate,
    couponCode,
    setVideoData
) => {
    return (dispatch) => {
        let url =
            API_URL +
            `/api/v1/dashboard/videowatchdata/video-retention/?collection=${collectionId}&module=${moduleId}&video=${videoId}&after=${startDate}&before=${endDate}&coupon=${couponCode}`;

        return fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access")
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                return setVideoData(data);
            })
            .catch((error) => {
                console.log(error);
                if (parseInt(error.message) === 401) {
                    return dispatch(
                        getNewAccessToken(() =>
                            fetchVideoRententionData(
                                collectionId,
                                moduleId,
                                videoId,
                                startDate,
                                endDate,
                                couponCode,
                                setVideoData
                            )
                        )
                    );
                } else {
                    return message.error("Something went wrong.");
                }
            });
    };
};

export const fetchRecentQnAActivity = (couponCode, days) => {
    return (dispatch) => {
        let url =
            API_URL +
            `/api/v1/dashboard/qna/recent-activity/?coupon=${couponCode}&days=${days}`;
        dispatch(setRecentQnAActivityStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("access")
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setRecentQnAActivity(data));
                dispatch(setRecentQnAActivityStatus("LOADED"));
            })
            .catch((error) => {
                console.log(error);
                if (parseInt(error.message) === 401) {
                    return dispatch(
                        getNewAccessToken(() =>
                            fetchRecentQnAActivity(couponCode)
                        )
                    );
                } else {
                    dispatch(setRecentQnAActivityStatus("FAILED"));

                    return message.error("Something went wrong.");
                }
            });
    };
};

export const postQnAReplyFromRecent = (
    collectionId,
    moduleId,
    videoId,
    questionId,
    couponId,
    qnaLevel,
    formData,
    handleAfterSuccess
) => {
    let url;
    if (qnaLevel == "collection") {
        url =
            API_URL +
            `/api/v1/collections/${collectionId}/qnaquestions/${questionId}/qnaanswers/`;
    } else if (qnaLevel == "module") {
        url =
            API_URL +
            `/api/v1/collections/${collectionId}/modules/${moduleId}/qnaquestions/${questionId}/qnaanswers/`;
    } else {
        url =
            API_URL +
            `/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/qnaquestions/${questionId}/qnaanswers/`;
    }

    return (dispatch) => {
        return fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                text: formData["text"],
                anonymous: formData["anonymous"],
                coupon: couponId
            })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(addNewQnAReplyFromRecent(qnaLevel, questionId, data));
                handleAfterSuccess();
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            postQnAReplyFromRecent(
                                collectionId,
                                moduleId,
                                videoId,
                                couponId,
                                formData,
                                handleAfterSuccess
                            )
                        )
                    );
                } else {
                    console.log(error);
                }
            });
    };
};

export const fetchUserWatchHistoryOfModule = (
    collectionId,
    couponCode,
    openModuleKey
) => {
    return (dispatch) => {
        let url =
            API_URL +
            `/api/v1/collections/${collectionId}/watch-history/?coupon=${couponCode}&module=${openModuleKey}`;
        dispatch(setUserWatchHistoryOfModuleStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setUserWatchHistoryOfModule(openModuleKey, data));
                dispatch(setUserWatchHistoryOfModuleStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchUserWatchHistoryOfModule(
                                collectionId,
                                couponCode,
                                openModuleKey
                            )
                        )
                    );
                } else {
                    console.log(error);
                    dispatch(setUserWatchHistoryOfModuleStatus("FAILED"));
                }
            });
    };
};

export const fetchQuizQuestionAttemptDataForVideo = (
    couponCode,
    userId,
    videoId
) => {
    return (dispatch) => {
        let url =
            API_URL +
            `/api/v1/users/${userId}/videos/${videoId}/quiz-question-attempts/?coupon=${couponCode}`;
        dispatch(setQuizQuestionAttemptDataStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setQuizQuestionAttemptData(data));
                dispatch(setQuizQuestionAttemptDataStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchQuizQuestionAttemptDataForVideo(
                                couponCode,
                                userId,
                                videoId
                            )
                        )
                    );
                } else {
                    console.log(error);
                    dispatch(setQuizQuestionAttemptDataStatus("FAILED"));
                }
            });
    };
};

export const fetchQnAQuestionsByUser = (
    couponCode,
    userId,
    qnaLevel,
    collectionId,
    moduleId,
    videoId
) => {
    return (dispatch) => {
        let url;
        if (qnaLevel == "collection") {
            url =
                API_URL +
                `/api/v1/users/${userId}/collections/${collectionId}/qnaquestions/?coupon=${couponCode}`;
        } else if (qnaLevel == "module") {
            url =
                API_URL +
                `/api/v1/users/${userId}/collections/${collectionId}/modules/${moduleId}/qnaquestions/?coupon=${couponCode}`;
        } else {
            url =
                API_URL +
                `/api/v1/users/${userId}/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/qnaquestions/?coupon=${couponCode}`;
        }
        dispatch(setUserQnAQuestionsByLevelStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setUserQnAQuestionsByLevel(data));
                dispatch(setUserQnAQuestionsByLevelStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchQnAQuestionsByUser(
                                couponCode,
                                userId,
                                qnaLevel,
                                collectionId,
                                moduleId,
                                videoId
                            )
                        )
                    );
                } else {
                    console.log(error);
                    dispatch(setUserQnAQuestionsByLevelStatus("FAILED"));
                }
            });
    };
};

export const fetchModuleWatchPercentageData = (
    couponCode,
    moduleId,
    startDate,
    endDate
) => {
    return (dispatch) => {
        let url =
            API_URL +
            `/api/v1/dashboard/module-watch-percentage-data/?code=${couponCode}&module=${moduleId}&after=${startDate}&before${endDate}`;
        dispatch(setModuleWatchPercentageDataStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setModuleWatchPercentageData(data));
                dispatch(setModuleWatchPercentageDataStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchModuleWatchPercentageData(
                                couponCode,
                                startDate,
                                endDate
                            )
                        )
                    );
                } else {
                    dispatch(setModuleWatchPercentageDataStatus("FAILED"));
                }
            });
    };
};

export const fetchClassStatistics = (couponCode, startDate, endDate) => {
    return (dispatch) => {
        let url =
            API_URL + `/api/v1/dashboard/class-statistics/?code=${couponCode}`;
        dispatch(setClassStatisticsStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setClassStatistics(data));
                dispatch(setClassStatisticsStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchClassStatistics(couponCode, startDate, endDate)
                        )
                    );
                } else {
                    dispatch(setClassStatisticsStatus("FAILED"));
                }
            });
    };
};

export const fetchClassMedianTimeSpent = (couponCode, currentUserId) => {
    return (dispatch) => {
        let url =
            API_URL +
            `/api/v1/dashboard/videowatchdata/users/${currentUserId}/weekly-time-spent/?code=${couponCode}`;
        dispatch(setClassMedianTimeSpentStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setClassMedianTimeSpent(data));
                dispatch(setClassMedianTimeSpentStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchClassMedianTimeSpent(couponCode, currentUserId)
                        )
                    );
                } else {
                    console.log(error);
                    dispatch(setClassMedianTimeSpentStatus("FAILED"));
                }
            });
    };
};

export const fetchVideoStatistics = (
    collectionId,
    moduleId,
    videoId,
    couponCode
) => {
    return (dispatch) => {
        let url =
            API_URL +
            `/api/v1/dashboard/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/statistics/?code=${couponCode}`;
        dispatch(setVideoStatisticsStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setVideoStatistics(data));
                dispatch(setVideoStatisticsStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchVideoStatistics(
                                collectionId,
                                moduleId,
                                videoId,
                                couponCode
                            )
                        )
                    );
                } else {
                    console.log(error);
                    dispatch(setVideoStatisticsStatus("FAILED"));
                }
            });
    };
};

export const fetchQuizQuestionStatistics = (
    collectionId,
    moduleId,
    videoId,
    quizQuestionId,
    couponCode
) => {
    return (dispatch) => {
        let url =
            API_URL +
            `/api/v1/dashboard/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/quizquestions/${quizQuestionId}/statistics/?code=${couponCode}`;
        dispatch(setQuizQuestionStatisticsStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setQuizQuestionStatistics(data));
                dispatch(setQuizQuestionStatisticsStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchQuizQuestionStatistics(
                                collectionId,
                                moduleId,
                                videoId,
                                quizQuestionId,
                                couponCode
                            )
                        )
                    );
                } else {
                    console.log(error);
                    dispatch(setQuizQuestionStatisticsStatus("FAILED"));
                }
            });
    };
};

export const fetchQnAStatistics = (
    collectionId,
    moduleId,
    videoId,
    couponCode
) => {
    return (dispatch) => {
        let url =
            API_URL +
            `/api/v1/dashboard/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/qnaquestions/statistics/?code=${couponCode}`;
        dispatch(setQnAStatisticsStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setQnAStatistics(data));
                dispatch(setQnAStatisticsStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchQnAStatistics(
                                collectionId,
                                moduleId,
                                videoId,
                                couponCode
                            )
                        )
                    );
                } else {
                    console.log(error);
                    dispatch(setQnAStatisticsStatus("FAILED"));
                }
            });
    };
};

export const fetchStudentSummaries = (userId, couponCode) => {
    return (dispatch) => {
        let url =
            API_URL +
            `/api/v1/dashboard/users/${userId}/student-summaries/?code=${couponCode}`;
        dispatch(setStudentSummariesStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setStudentSummaries(data));
                dispatch(setStudentSummariesStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchStudentSummaries(userId, couponCode)
                        )
                    );
                } else {
                    console.log(error);
                    dispatch(setStudentSummariesStatus("FAILED"));
                }
            });
    };
};

export const fetchStudentCourseSummary = (courseId) => {
    return (dispatch) => {
        let url =
            API_URL +
            `/api/v1/dashboard/users/student-course-summaries/${courseId}`;
        dispatch(setStudentCourseSummaryStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setStudentCourseSummaryData(data));
                dispatch(setStudentCourseSummaryStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchStudentCourseSummary(courseId)
                        )
                    );
                } else {
                    console.log(error);
                    dispatch(setStudentCourseSummaryStatus("FAILED"));
                }
            });
    };
};

export const setStudentCourseSummaryStatus = (status) => {
    return {
        type: "SET_STUDENT_COURSE_SUMMARY_STATUS",
        status
    };
};

export const setStudentCourseSummaryData = (data) => {
    return {
        type: "SET_STUDENT_COURSE_SUMMARY_DATA",
        data
    };
};

export const fetchStudentCourseVideoActivities = (courseId) => {
    return (dispatch) => {
        let url =
            API_URL +
            `/api/v1/dashboard/videowatchdata/individual-student-course-video-activity/${courseId}`;
        dispatch(setStudentCourseVideoActivitiesStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                let formattedVideos = {};
                // Returns maximum video duration from all data (used for
                // scaling purposes))
                let maximumVideoDuration = Object.values(data)
                    .map((x) => x.videoDuration)
                    .reduce((a, b) => {
                        return Math.max(a, b);
                    });

                for (let video in data) {
                    // Example videoObj: {videoTitle: "Conditionals: The IF
                    // Statement", moduleId: 8, collectionId: 5, data: Array(26)}
                    let videoObj = data[video];
                    videoObj.dataSum = videoObj.data.reduce((a, b) => a + b, 0);

                    // Format videos array into object, with keys being module IDs
                    // and values being an array of videos in that module
                    let moduleId = videoObj.moduleId;
                    if (formattedVideos.hasOwnProperty(moduleId)) {
                        formattedVideos[moduleId].push(videoObj);
                    } else {
                        formattedVideos[moduleId] = [videoObj];
                    }
                }
                dispatch(
                    setStudentCourseVideoActivitiesData({
                        maxVideoDuration: maximumVideoDuration,
                        watchedVideos: formattedVideos
                    })
                );
                dispatch(setStudentCourseVideoActivitiesStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchStudentCourseVideoActivities(courseId)
                        )
                    );
                } else {
                    console.log(error);
                    dispatch(setStudentCourseVideoActivitiesStatus("FAILED"));
                }
            });
    };
};

export const setStudentCourseVideoActivitiesStatus = (status) => {
    return {
        type: "SET_STUDENT_COURSE_VIDEO_ACTIVITIES_STATUS",
        status
    };
};

export const setStudentCourseVideoActivitiesData = (data) => {
    return {
        type: "SET_STUDENT_COURSE_VIDEO_ACTIVITIES_DATA",
        data
    };
};

export const setStudentSummariesStatus = (status) => {
    return {
        type: "SET_STUDENT_SUMMARIES_STATUS",
        status
    };
};

export const setStudentSummaries = (data) => {
    return {
        type: "SET_STUDENT_SUMMARIES",
        data
    };
};

export const fetchDashboardReports = (couponCode) => {
    return (dispatch) => {
        let url = API_URL + `/api/v1/dashboard/reports/?code=${couponCode}`;
        dispatch(setDashboardReportsStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setDashboardReports(data));
                dispatch(setDashboardReportsStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchDashboardReports(couponCode)
                        )
                    );
                } else {
                    console.log(error);
                    dispatch(setDashboardReportsStatus("FAILED"));
                }
            });
    };
};

export const setDashboardReportsStatus = (status) => {
    return {
        type: "SET_DASHBOARD_REPORTS_STATUS",
        status
    };
};

export const setDashboardReports = (data) => {
    return {
        type: "SET_DASHBOARD_REPORTS",
        data
    };
};

export const requestDashboardReport = (couponCode, startDate, endDate) => {
    return (dispatch) => {
        let url =
            API_URL + `/api/v1/dashboard/reports/generate/?code=${couponCode}`;
        dispatch(setDashboardReportRequestStatus("LOADING"));
        return fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                startDate: startDate,
                endDate: endDate
            })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(appendDashboardReport(data));
                dispatch(setDashboardReportRequestStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            requestDashboardReport(couponCode)
                        )
                    );
                } else {
                    console.log(error);
                    dispatch(setDashboardReportRequestStatus("FAILED"));
                }
            });
    };
};

export const appendDashboardReport = (data) => {
    return {
        type: "APPEND_DASHBOARD_REPORT",
        data
    };
};

export const setDashboardReportRequestStatus = (status) => {
    return {
        type: "SET_DASHBOARD_REPORT_REQUEST_STATUS",
        status
    };
};

export const clearVideoStatisticsDrawerData = () => {
    return {
        type: "CLEAR_VIDEO_STATISTICS_DRAWER_DATA"
    };
};

export const setQnAStatisticsStatus = (status) => {
    return {
        type: "SET_QNA_STATISTICS_STATUS",
        status
    };
};

export const setQnAStatistics = (data) => {
    return {
        type: "SET_QNA_STATISTICS",
        data
    };
};

export const setQuizQuestionStatisticsStatus = (status) => {
    return {
        type: "SET_QUIZ_QUESTION_STATISTICS_STATUS",
        status
    };
};

export const setQuizQuestionStatistics = (data) => {
    return {
        type: "SET_QUIZ_QUESTION_STATISTICS",
        data
    };
};

export const setVideoStatisticsStatus = (status) => {
    return {
        type: "SET_VIDEO_STATISTICS_STATUS",
        status
    };
};

export const setVideoStatistics = (data) => {
    return {
        type: "SET_VIDEO_STATISTICS",
        data
    };
};

export const setClassMedianTimeSpentStatus = (status) => {
    return {
        type: "SET_CLASS_MEDIAN_TIME_SPENT_STATUS",
        status
    };
};

export const setClassMedianTimeSpent = (timeSpent) => {
    return {
        type: "SET_CLASS_MEDIAN_TIME_SPENT",
        timeSpent
    };
};

export const setModuleWatchPercentageData = (moduleWatchPercentageData) => {
    return {
        type: "SET_MODULE_WATCH_PERCENTAGE_DATA",
        moduleWatchPercentageData
    };
};

export const setModuleWatchPercentageDataStatus = (status) => {
    return {
        type: "SET_MODULE_WATCH_PERCENTAGE_DATA_STATUS",
        status
    };
};

export const setClassStatistics = (classStatistics) => {
    return {
        type: "SET_CLASS_STATISTICS",
        classStatistics
    };
};

export const setClassStatisticsStatus = (status) => {
    return {
        type: "SET_CLASS_STATISTICS_STATUS",
        status
    };
};

export const setQuizQuestionAttemptData = (attemptData) => {
    return {
        type: "SET_QUIZ_QUESTION_ATTEMPT_DATA",
        attemptData
    };
};

export const setQuizQuestionAttemptDataStatus = (status) => {
    return {
        type: "SET_QUIZ_QUESTION_ATTEMPT_DATA_STATUS",
        status
    };
};

export const setUserQnAQuestionsByLevel = (qnaQuestions) => {
    return {
        type: "SET_USER_QNA_QUESTIONS_BY_LEVEL",
        qnaQuestions
    };
};

export const setUserQnAQuestionsByLevelStatus = (status) => {
    return {
        type: "SET_USER_QNA_QUESTIONS_BY_LEVEL_STATUS",
        status
    };
};
export const setUserWatchHistoryOfModule = (openModuleKey, watchHistory) => {
    return {
        type: "SET_USER_WATCH_HISTORY_OF_MODULE",
        openModuleKey,
        watchHistory
    };
};
export const setUserWatchHistoryOfModuleStatus = (status) => {
    return {
        type: "SET_USER_WATCH_HISTORY_OF_MODULE_STATUS",
        status
    };
};
export const addNewQnAReplyFromRecent = (qnaLevel, questionId, data) => {
    return {
        type: "ADD_NEW_QNA_REPLY_FROM_RECENT",
        qnaLevel,
        questionId,
        data
    };
};
export const setRecentQnAActivityStatus = (status) => {
    return {
        type: "SET_RECENT_QNA_ACTIVITY_STATUS",
        status
    };
};

export const setRecentQnAActivity = (recentActivity) => {
    return {
        type: "SET_RECENT_QNA_ACTIVITY",
        recentActivity
    };
};
// clears all selected videos
export const clearSelectedVideos = () => {
    return {
        type: "CLEAR_SELECTED_VIDEOS"
    };
};

export const clearDashboardData = () => {
    return {
        type: "CLEAR_DASHBOARD_DATA"
    };
};
