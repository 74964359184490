import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Carousel, Descriptions, Image, Modal } from "antd";
import Sedgewick from "./sedgewick.jpg";
import Nero from "./nero.jpg";
import InterdisciplinaryApproach from "./interdisciplinaryapproach.png";
import AnalysisOfAlgorithms from "./analysisofalgorithms.png";
import Algorithms from "./algorithms.png";
import LearningSVG from "./learning.svg";
import AnalyticsSVG from "./analytics.svg";
import WatchSVG from "./video.svg";
import DoneSVG from "./done.svg";
import InteractiveSVG from "./interactive.svg";
import PlatformSVG from "./platform.svg";
import { PlayCircleOutlined } from "@ant-design/icons";
import CategoryFilter from "./Collections/List/CategoryFilter";
import { connect } from "react-redux";
import Player from "@vimeo/player";

const Home = (props) => {
    const vimeoContainerRef = useRef(null);
    const [vimeoPlayer, setVimeoPlayer] = useState(null);
    const [introModalOpen, setIntroModalOpen] = useState(false);
    const [collectionBackgroundColor, setCollectionBackgroundColor] = useState(
        "home-collection-one"
    );

    const beforeChange = (from, to) => {
        if (to == 0) {
            setCollectionBackgroundColor("home-collection-one");
        } else if (to == 1) {
            setCollectionBackgroundColor("home-collection-two");
        } else if (to == 2) {
            setCollectionBackgroundColor("home-collection-three");
        }
    };

    useEffect(() => {
        if (introModalOpen && vimeoContainerRef?.current && !vimeoPlayer) {
            const player = new Player(vimeoContainerRef?.current, {
                id: "990206250",
                autoplay: true,
                width: "100%",
                height: "100%"
            });
            setVimeoPlayer(player);
        } else {
            setVimeoPlayer(null);
        }
    }, [introModalOpen, vimeoContainerRef]);

    return (
        <section>
            <div>
                <div className="home-page-landing">
                    <img
                        src={LearningSVG}
                        className="home-page-landing-image"
                    />
                    <div id="home-page-heading">
                        <h1>
                            Empowering Minds through <br />
                            <span
                                className="multi-sensory"
                                onClick={() => setIntroModalOpen(true)}>
                                Multi-Sensory
                            </span>{" "}
                            Learning
                        </h1>
                        <p>
                            A Repository of AI-boosted & Certified Skills
                            Courses in One Place
                        </p>
                        <div id="home-page-landing-btns">
                            <Link to="/collections/" id="home-page-explore-btn">
                                Explore Courses
                            </Link>{" "}
                            <Link to="/certifications/" id="be-an-author-btn">
                                Get a Certificate
                            </Link>
                            <Link to="/authors/" id="be-an-author-btn">
                                Build a Course
                            </Link>
                        </div>
                    </div>
                </div>

                {introModalOpen && (
                    <>
                        <Modal
                            className="intro-modal-home-page"
                            width={"50%"}
                            visible={introModalOpen}
                            footer={null}
                            onCancel={() => setIntroModalOpen(false)}
                            maskClosable={true}>
                            <div style={{ padding: 15 }}>
                                <Descriptions>
                                    <Descriptions.Item
                                        labelStyle={{
                                            fontSize: "18px"
                                        }}
                                        style={{
                                            textAlign: "center",
                                            fontSize: "18px"
                                        }}>
                                        Learning through cubits multi-sensory
                                        experience that includes video, audio,
                                        and text simultaneously enhances
                                        comprehension and retention by catering
                                        to different learning styles.
                                    </Descriptions.Item>
                                </Descriptions>
                                <div className="home-page-intro-video">
                                    <div ref={vimeoContainerRef}></div>
                                </div>
                            </div>
                        </Modal>
                    </>
                )}

                {/* Categories */}
                {props.status === "LOADED" && (
                    <>
                        <section className="home-category-section">
                            <h2>
                                Most Sought After AI-powered Technical Skills
                                Courses in One Place
                            </h2>
                            <CategoryFilter
                                collections={props.collections}
                                categories={props.collectionCategories}
                                categoryOnly={true}
                            />
                        </section>
                    </>
                )}

                <section id="why-cubits-works">
                    <h2>Why Cubits?</h2>
                    <div>
                        <div>
                            <img src={WatchSVG} className="watch-img" />
                            <div id="why-cubits-works-watch">
                                <p className={"home-img-caption-title"}>
                                    Watch
                                </p>
                                <p className={"home-img-caption"}>
                                    Watch videos within structured courses
                                    alongside synchronized transcripts
                                </p>
                                <Button>
                                    <Link to="/mock/video">View More</Link>
                                </Button>
                            </div>
                        </div>
                        <div>
                            <img src={DoneSVG} className="done-img" />
                            <div id="why-cubits-works-interact">
                                <p className={"home-img-caption-title"}>
                                    Interact
                                </p>
                                <p className={"home-img-caption"}>
                                    Increase engagement through Embedded Quizzes
                                    and Video Discussions
                                </p>
                                <Button>
                                    <Link to="/mock/video?qna=true">
                                        View More
                                    </Link>
                                </Button>
                            </div>
                        </div>

                        <div>
                            <img src={AnalyticsSVG} className="analyze-img" />
                            <div id="why-cubits-works-analyze">
                                <p className={"home-img-caption-title"}>
                                    Analyze
                                </p>
                                <p className={"home-img-caption"}>
                                    Visualize your engagement/performance and
                                    compare with the class statistics
                                </p>
                                <Button>
                                    <Link to="/mock/dashboard">View More</Link>
                                </Button>
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    id="home-collections"
                    className={collectionBackgroundColor}>
                    <div>
                        <div id="home-collections-section-title-container">
                            <h2 id="home-collections-section-title">Courses</h2>
                            <Link
                                to="/collections"
                                id="home-collections-section-view-all">
                                View All
                            </Link>
                        </div>
                        <div id="home-collections-carousel">
                            <Carousel
                                autoplay
                                dotPosition="right"
                                beforeChange={beforeChange}>
                                <div>
                                    <div className="home-collection">
                                        <div className="home-collection-image-bio">
                                            <Image
                                                src={InterdisciplinaryApproach}
                                                preview={false}
                                                className="home-collection-image"
                                            />
                                            <div className="home-collection-bio">
                                                <p>
                                                    By{" "}
                                                    <a
                                                        href="https://www.cubits.ai/creators/382"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        Robert Sedgewick
                                                    </a>{" "}
                                                    and{" "}
                                                    <a
                                                        href="https://www.cubits.ai/creators/385"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        Kevin Wayne
                                                    </a>
                                                </p>
                                                <Link
                                                    to="/collections/5/"
                                                    className="home-collection-view-btn">
                                                    View Full Course
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="home-collection-text">
                                            <h3>
                                                Computer Science: An
                                                Interdisciplinary Approach
                                            </h3>

                                            <p>
                                                This video course is a complete
                                                introduction to computer science
                                                in the context of scientific,
                                                engineering, and commercial
                                                applications. The goal of the
                                                course is to teach basic
                                                principles and practical issues,
                                                while at the same time preparing
                                                students to use computers
                                                effectively for applications in
                                                computer science, physics,
                                                biology, chemistry, engineering,
                                                and other disciplines.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="home-collection">
                                        <div className="home-collection-image-bio">
                                            <Image
                                                src={AnalysisOfAlgorithms}
                                                preview={false}
                                                className="home-collection-image"
                                            />
                                            <div className="home-collection-bio">
                                                <p>
                                                    By{" "}
                                                    <a
                                                        href="https://www.cubits.ai/creators/382"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        Robert Sedgewick
                                                    </a>
                                                </p>
                                                <Link
                                                    to="/collections/8/"
                                                    className="home-collection-view-btn">
                                                    View Full Course
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="home-collection-text">
                                            <h3>Analysis of Algorithms</h3>

                                            <p>
                                                People who analyze algorithms
                                                have double happiness. First of
                                                all they experience the sheer
                                                beauty of elegant mathematical
                                                patterns that surround elegant
                                                computational procedures. Then
                                                they receive a practical payoff
                                                when their theories make it
                                                possible to get other jobs done
                                                more quickly and more
                                                economically. D. E. Knuth
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="home-collection">
                                        <div className="home-collection-image-bio">
                                            <Image
                                                src={Algorithms}
                                                preview={false}
                                                className="home-collection-image"
                                            />
                                            <div className="home-collection-bio">
                                                <p>
                                                    By{" "}
                                                    <a
                                                        href="https://www.cubits.ai/creators/382"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        Robert Sedgewick
                                                    </a>{" "}
                                                    and{" "}
                                                    <a
                                                        href="https://www.cubits.ai/creators/385"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        Kevin Wayne
                                                    </a>
                                                </p>
                                                <Link
                                                    to="/collections/25/"
                                                    className="home-collection-view-btn">
                                                    View Full Course
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="home-collection-text">
                                            <h3>Algorithms</h3>

                                            <p>
                                                This course surveys the most
                                                important algorithms and data
                                                structures in use on computers
                                                today. Particular emphasis is
                                                given to algorithms for sorting,
                                                searching, and string
                                                processing. Fundamental
                                                algorithms in a number of other
                                                areas are covered as well,
                                                including geometric and graph
                                                algorithms. The course will
                                                concentrate on developing
                                                implementations, understanding
                                                their performance
                                                characteristics, and estimating
                                                their potential effectiveness in
                                                applications.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </section>

                <section id="home-instructors-section-container">
                    <div id="home-instructors-section">
                        <div id="home-instructors-section-text">
                            <h5>FOR INSTRUCTORS</h5>

                            <p>
                                Personalize video courses using new or existing
                                videos that are instantly ready to be
                                distributed to students
                            </p>
                            <br />
                            <Link to="/creator/" id="home-instructors-btn">
                                Learn More
                            </Link>
                        </div>
                        <div>
                            <img src={PlatformSVG} />
                        </div>
                    </div>
                </section>
                <div id="home-quote-carousel-container">
                    <Carousel autoplay autoplaySpeed={10000}>
                        <div>
                            <div className="home-instructor-quote-container">
                                <div className="home-instructor-quote-credits">
                                    <img
                                        src={Sedgewick}
                                        className="home-instructor-avatar"
                                    />
                                    <div className="home-instructor-credit">
                                        <h1>Robert Sedgewick</h1>
                                        <p>Princeton University</p>
                                    </div>
                                </div>
                                <div className="home-instructor-quote">
                                    <blockquote>
                                        “As technology provides compelling new
                                        opportunities for education, students
                                        need affordable access to curated
                                        content, teachers need flexible tools
                                        for aggregation and analytics, and
                                        content creators need wide distribution
                                        and fair compensation. CUbits.ai is a
                                        modern platform for all three, and now
                                        preferred for all of my online
                                        lectures.”
                                    </blockquote>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div className="home-instructor-quote-container">
                                <div className="home-instructor-quote-credits">
                                    <img
                                        src={Nero}
                                        className="home-instructor-avatar"
                                    />
                                    <div className="home-instructor-credit">
                                        <h1>Dave Nero</h1>
                                        <p>University of Pittsburgh</p>
                                    </div>
                                </div>
                                <div className="home-instructor-quote">
                                    <blockquote>
                                        "CUbits.ai is the natural evolution of
                                        educational videos. The searchable video
                                        transcripts mean that my students can
                                        find the information they need
                                        efficiently, while the integrated
                                        discussion feature places common
                                        questions and their answers right next
                                        to the video. The analytics let me see
                                        which videos are the most watched (or
                                        re-watched), so as the instructor, I
                                        know what's working and what isn't."
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                </div>
                <section id="home-learners-section-container">
                    <div id="home-learners-section">
                        <div id="home-learners-section-visual">
                            <img src={InteractiveSVG} />
                        </div>
                        <div id="home-learners-section-text">
                            <h5>FOR LEARNERS</h5>

                            <p>
                                Learn and engage through a comprehensive,
                                interactive platform. Learn from trusted
                                instructors, always know where to look
                            </p>
                            <br />
                            <Link to="/collections/">
                                <button id="home-learners-watch-btn">
                                    <PlayCircleOutlined /> Watch
                                </button>
                            </Link>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => {
    return {
        collections: state.collectionReducer.originalCollections,
        collectionCategories: state.collectionReducer.collectionCategories,
        status: state.collectionReducer.fetchingCollectionsStatus
    };
};
export default connect(mapStateToProps, {})(Home);
