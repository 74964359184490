import { Button, Popconfirm, Space } from "antd";
import { API_URL } from "../const";
import TRACKING_EVENTS from "../_tracking/trackingEvents";
import trackingInstance from "../_tracking/Tracking";
import { connect } from "react-redux";
import { redirectToLogin, redirectToSignUp } from "../utils";

const LoginSignupConfirmButton = ({
    id,
    className = "",
    title,
    buttonType = "default",
    buttonText,
    icon = undefined,
    buttonBlock = false,
    trackingEvent = TRACKING_EVENTS.NONE,
    trackingData = {},
    currentUser = undefined
}) => {
    const onButtonClick = (event) => {
        if (trackingEvent === TRACKING_EVENTS.NONE) return;
        trackingInstance.trackEvent(trackingEvent, trackingData, currentUser);
    };

    const handleOnSignupConfirm = () => {
        redirectToSignUp(trackingData);
        return;
    };

    const handleOnLoginConfirm = () => {
        redirectToLogin(trackingData);
        return;
    };

    return (
        <Space className="ant-space-responsive">
            <Popconfirm
                title={title}
                onConfirm={handleOnSignupConfirm}
                onCancel={handleOnLoginConfirm}
                okText="Signup"
                cancelText="Login">
                <Button
                    block={buttonBlock}
                    type={buttonType}
                    icon={icon || undefined}
                    className={className}
                    id={id}
                    onClick={onButtonClick}>
                    {buttonText}
                </Button>
            </Popconfirm>
        </Space>
    );
};

const mapStateToProps = (state) => {
    return {
        currentUser: state.userReducer.user
    };
};
export default connect(mapStateToProps, {})(LoginSignupConfirmButton);
