const initialState = {
    singleCollection: null,
    singleCollectionIsSet: false,
    singleModule: null,
    singleVideo: null,
    singleModuleIsSet: false,
    singleVideoIsSet: false,
    originalCollections: [],
    categoryFilteredCollections: [],
    collectionCategories: [],
    fetchingCollectionsStatus: "NOT LOADED",
    fetchingCollectionCategoriesStatus: "NOT LOADED",
    collectionDoesNotExist: false,
    contentDoesNotExist: false,
    fetchSingleCollectionAccessStatus: "NOT LOADED",
    fetchSingleCollectionStatus: "NOT LOADED",
    singleCollectionAccess: {},
    videoQnA: [],
    moduleQnA: [],
    collectionQnA: [],
    videoQnAStatus: "NOT LOADED",
    moduleQnAStatus: "NOT LOADED",
    collectionQnAStatus: "NOT LOADED",
    quizQuestions: [],
    quizQuestionStatus: "NOT LOADED",
    transcript: [],
    transcriptStatus: "NOT LOADED"
};

export default (state = initialState, action) => {
    let filteredResponses, qnaLevel, qnaQuestionsArray, qnaParentQuestionIndex;
    switch (action.type) {
        case "ADD_MODULE_ACCESS":
            return Object.assign({}, state, {
                singleCollectionAccess: {
                    ...state.singleCollectionAccess,
                    ...action.moduleAccess
                }
            });
        case "CLEAR_SELECTED_MODULE_AND_VIDEO":
            return Object.assign({}, state, {
                singleModule: null,
                singleVideo: null,
                singleModuleIsSet: false,
                singleVideoIsSet: false
            });
        case "CLEAR_VIDEO_PAGE_CONTENT":
            return Object.assign({}, state, {
                singleCollection: null,
                singleModule: null,
                singleVideo: null,
                singleCollectionIsSet: false,
                singleModuleIsSet: false,
                singleVideoIsSet: false,
                contentDoesNotExist: false
            });
        case "CLEAR_SINGLE_COLLECTION":
            return Object.assign({}, state, {
                singleCollection: null,
                singleCollectionIsSet: false,
                collectionDoesNotExist: false
            });
        case "CLEAR_QUIZ_QUESTIONS":
            return Object.assign({}, state, { quizQuestions: [] });
        case "FIND_AND_SET_SINGLE_COLLECTION":
            var result = state.originalCollections.find((collection) => {
                return collection.id === parseInt(action.collectionId);
            });
            if (result === null) {
                return Object.assign({}, state, {
                    collectionDoesNotExist: true
                });
            }
            return Object.assign({}, state, {
                singleCollection: result,
                singleCollectionIsSet: true
            });
        case "GET_VIDEO_DATA":
            var moduleResult = state.singleCollection.modules.find((module) => {
                return module.id === parseInt(action.moduleId);
            });
            if (!moduleResult) {
                return Object.assign({}, state, {
                    contentDoesNotExist: true
                });
            }
            var videoResult = moduleResult.videos.find((video) => {
                return video.id === parseInt(action.videoId);
            });
            if (!videoResult) {
                return Object.assign({}, state, {
                    contentDoesNotExist: true
                });
            }
            return Object.assign({}, state, {
                singleModule: moduleResult,
                singleModuleIsSet: true,
                singleVideo: videoResult,
                singleVideoIsSet: true
            });
        case "ADD_NEW_QNA_QUESTION":
            if (action.qnaLevel === "collection") {
                return Object.assign({}, state, {
                    collectionQnA: [action.newQuestion, ...state.collectionQnA]
                });
            } else if (action.qnaLevel === "module") {
                return Object.assign({}, state, {
                    moduleQnA: [action.newQuestion, ...state.moduleQnA]
                });
            } else if (action.qnaLevel === "video") {
                return Object.assign({}, state, {
                    videoQnA: [action.newQuestion, ...state.videoQnA]
                });
            }
        case "ADD_NEW_QNA_REPLY":
            let qnaVariable;
            if (action.qnaLevel === "collection") {
                qnaVariable = "collectionQnA";
            } else if (action.qnaLevel === "module") {
                qnaVariable = "moduleQnA";
            } else if (action.qnaLevel === "video") {
                qnaVariable = "videoQnA";
            }
            let newReply = { ...action.reply, newComment: true };
            let qnaQuestions = [...state[qnaVariable]];
            let qnaQuestionIndex = qnaQuestions.findIndex(
                (question) => question.id == action.questionId
            );
            let targetQnAQuestion = qnaQuestions[qnaQuestionIndex];
            qnaQuestions[qnaQuestionIndex]["responses"] = [
                ...targetQnAQuestion.responses,
                newReply
            ];
            return Object.assign({}, state, { [qnaVariable]: qnaQuestions });
        case "REMOVE_DELETED_REPLY":
            if (action.qnaLevel === "collection") {
                qnaLevel = "collectionQnA";
            } else if (action.qnaLevel === "module") {
                qnaLevel = "moduleQnA";
            } else if (action.qnaLevel === "video") {
                qnaLevel = "videoQnA";
            }
            qnaQuestionsArray = [...state[qnaLevel]];
            qnaParentQuestionIndex = qnaQuestionsArray.findIndex(
                (question) => question.id == action.questionId
            );
            filteredResponses = qnaQuestionsArray[
                qnaParentQuestionIndex
            ].responses.filter((response) => response.id !== action.answerId);
            qnaQuestionsArray[qnaParentQuestionIndex] = {
                ...qnaQuestionsArray[qnaParentQuestionIndex],
                responses: filteredResponses
            };
            return Object.assign({}, state, { [qnaLevel]: qnaQuestionsArray });
        case "REMOVE_DELETED_QNA_QUESTION":
            if (action.qnaLevel === "collection") {
                qnaLevel = "collectionQnA";
            } else if (action.qnaLevel === "module") {
                qnaLevel = "moduleQnA";
            } else if (action.qnaLevel === "video") {
                qnaLevel = "videoQnA";
            }
            qnaQuestionsArray = [...state[qnaLevel]];
            let qnaQuestionToDeleteIndex = qnaQuestionsArray.findIndex(
                (question) => question.id == action.questionId
            );
            qnaQuestionsArray.splice(qnaQuestionToDeleteIndex, 1);
            return Object.assign({}, state, { [qnaLevel]: qnaQuestionsArray });
        case "SET_FETCH_SINGLE_COLLECTION_ACCESS_STATUS":
            return Object.assign({}, state, {
                fetchSingleCollectionAccessStatus: action.status
            });
        case "SET_FETCH_SINGLE_COLLECTION_STATUS":
            return Object.assign({}, state, {
                fetchSingleCollectionStatus: action.status
            });
        case "SET_SINGLE_COLLECTION_ACCESS":
            return Object.assign({}, state, {
                singleCollectionAccess: action.accessData
            });
        case "SET_VIDEO_QNA_DATA":
            return Object.assign({}, state, { videoQnA: action.qna });
        case "SET_MODULE_QNA_DATA":
            return Object.assign({}, state, { moduleQnA: action.qna });
        case "SET_COLLECTION_QNA_DATA":
            return Object.assign({}, state, { collectionQnA: action.qna });
        case "SET_VIDEO_QNA_STATUS":
            return Object.assign({}, state, { videoQnAStatus: action.status });
        case "SET_MODULE_QNA_STATUS":
            return Object.assign({}, state, { moduleQnAStatus: action.status });
        case "SET_COLLECTION_QNA_STATUS":
            return Object.assign({}, state, {
                collectionQnAStatus: action.status
            });
        case "RESET_TRANSCRIPT_DATA":
            return Object.assign({}, state, { transcript: [] });
        case "SAVE_TRANSCRIPT":
            return Object.assign({}, state, { transcript: action.transcript });
        case "SAVE_QUIZ_QUESTIONS":
            return Object.assign({}, state, {
                quizQuestions: action.questions
            });
        case "SET_SINGLE_COLLECTION":
            return Object.assign({}, state, {
                singleCollection: action.collectionData,
                singleCollectionIsSet: true,
                notAnOriginalCollection: false
            });
        case "SET_TRANSCRIPT_STATUS":
            return Object.assign({}, state, {
                transcriptStatus: action.status
            });
        case "SET_QUIZ_QUESTION_STATUS":
            return Object.assign({}, state, {
                quizQuestionStatus: action.status
            });
        case "SET_FETCHING_COLLECTIONS_STATUS":
            return Object.assign({}, state, {
                fetchingCollectionsStatus: action.status
            });
        case "SET_FETCHING_COLLECTION_CATEGORIES_STATUS":
            return Object.assign({}, state, {
                fetchingCollectionCategoriesStatus: action.status
            });
        case "SET_ORIGINAL_COLLECTIONS":
            return Object.assign({}, state, {
                originalCollections: action.collections,
                categoryFilteredCollections: action.collections
            });
        case "SET_CATEGORY_FILTERED_COLLECTIONS":
            return Object.assign({}, state, {
                categoryFilteredCollections: action.categoryFilteredCollections
            });
        case "SET_COLLECTION_CATEGORIES":
            return Object.assign({}, state, {
                collectionCategories: action.categories
            });
        case "SET_LATEST_QUIZ_QUESTION_ATTEMPT":
            var quizQuestionsArray = [...state.quizQuestions];
            var questionIndex = quizQuestionsArray.findIndex((question) => {
                return question.id == action.questionId;
            });
            quizQuestionsArray[questionIndex] = {
                ...quizQuestionsArray[questionIndex],
                latestAttempt: action.attemptData
            };
            return Object.assign({}, state, {
                quizQuestions: quizQuestionsArray
            });
        case "UPDATE_REMOVED_QNA_QUESTION":
            if (action.qnaLevel === "collection") {
                qnaLevel = "collectionQnA";
            } else if (action.qnaLevel === "module") {
                qnaLevel = "moduleQnA";
            } else if (action.qnaLevel === "video") {
                qnaLevel = "videoQnA";
            }
            qnaQuestionsArray = [...state[qnaLevel]];
            qnaParentQuestionIndex = qnaQuestionsArray.findIndex(
                (question) => question.id == action.questionId
            );
            qnaQuestionsArray[qnaParentQuestionIndex] =
                action.updatedQnAQuestion;
            return Object.assign({}, state, { [qnaLevel]: qnaQuestionsArray });
        case "UPDATE_ORIGINAL_COLLECTION":
            var originalCollections = state.originalCollections;
            let updatedCollectionIndex = originalCollections.findIndex(
                (collection) => action.collectionData.id == collection.id
            );
            originalCollections[updatedCollectionIndex] = action.collectionData;
            return Object.assign({}, state, {
                originalCollections: originalCollections
            });
        default:
            return state;
    }
};
