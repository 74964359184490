import { getNewAccessToken } from "./user.js";
import { message } from "antd";
import { API_URL } from "../const.js";
import { encodeQueryParams } from "../helper.js";
import { setVideoList, setVideoListStatus } from "./dashboard.js";

export const addModuleAccess = (moduleAccess) => {
    return {
        type: "ADD_MODULE_ACCESS",
        moduleAccess
    };
};

export const clearVideoPageContent = () => {
    return {
        type: "CLEAR_VIDEO_PAGE_CONTENT"
    };
};

export const clearSelectedModuleAndVideo = () => {
    return {
        type: "CLEAR_SELECTED_MODULE_AND_VIDEO"
    };
};
export const clearSingleCollection = () => {
    return {
        type: "CLEAR_SINGLE_COLLECTION"
    };
};
export const findAndSetSingleCollection = (collectionId) => {
    return {
        type: "FIND_AND_SET_SINGLE_COLLECTION",
        collectionId
    };
};

export const resetTranscriptData = () => {
    return {
        type: "RESET_TRANSCRIPT_DATA"
    };
};

export const setSingleCollection = (collectionData) => {
    return {
        type: "SET_SINGLE_COLLECTION",
        collectionData
    };
};

export const setFetchingCollections = (status) => {
    return {
        type: "SET_FETCHING_COLLECTIONS",
        status
    };
};

export const setOriginalCollections = (collections) => {
    return {
        type: "SET_ORIGINAL_COLLECTIONS",
        collections
    };
};

export const setCategoryFilteredCollections = (collections) => {
    return {
        type: "SET_CATEGORY_FILTERED_COLLECTIONS",
        categoryFilteredCollections: collections
    };
};

export const setCollectionCategories = (categories) => {
    return {
        type: "SET_COLLECTION_CATEGORIES",
        categories
    };
};

export const saveTranscript = (transcript) => {
    return {
        type: "SAVE_TRANSCRIPT",
        transcript
    };
};

export const setTranscriptStatus = (status) => {
    return {
        type: "SET_TRANSCRIPT_STATUS",
        status
    };
};

export const addNewQnAQuestion = (qnaLevel, newQuestion) => {
    return {
        type: "ADD_NEW_QNA_QUESTION",
        qnaLevel,
        newQuestion
    };
};

export const addNewQnAReply = (qnaLevel, questionId, reply) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "ADD_NEW_QNA_REPLY",
                qnaLevel,
                questionId,
                reply
            });
            resolve();
        });
    };
};

export const setVideoQnAData = (qna) => {
    return {
        type: "SET_VIDEO_QNA_DATA",
        qna
    };
};
export const setModuleQnAData = (qna) => {
    return {
        type: "SET_MODULE_QNA_DATA",
        qna
    };
};
export const setCollectionQnAData = (qna) => {
    return {
        type: "SET_COLLECTION_QNA_DATA",
        qna
    };
};
export const saveQuizQuestions = (questions) => {
    return {
        type: "SAVE_QUIZ_QUESTIONS",
        questions
    };
};

export const setQuizQuestionStatus = (status) => {
    return {
        type: "SET_QUIZ_QUESTION_STATUS",
        status
    };
};

export const setVideoQnAStatus = (status) => {
    return {
        type: "SET_VIDEO_QNA_STATUS",
        status
    };
};
export const setModuleQnAStatus = (status) => {
    return {
        type: "SET_MODULE_QNA_STATUS",
        status
    };
};
export const setCollectionQnAStatus = (status) => {
    return {
        type: "SET_COLLECTION_QNA_STATUS",
        status
    };
};

export const updateOriginalCollection = (collectionData) => {
    return {
        type: "UPDATE_ORIGINAL_COLLECTION",
        collectionData
    };
};

export const clearQuizQuestions = () => {
    return {
        type: "CLEAR_QUIZ_QUESTIONS"
    };
};

export const fetchSingleCollection = (
    collectionId,
    unlistedKey,
    promoCode,
    handleCollectionNotFound
) => {
    let url = API_URL + `/api/v1/collections/${collectionId}/`;
    if (unlistedKey != null || promoCode !== null) {
        let obj = {};
        if (unlistedKey != null) {
            obj = { ...obj, key: unlistedKey };
        }
        if (promoCode !== null) {
            obj = { ...obj, prmotion_code: promoCode };
        }
        url += `?${encodeQueryParams(obj)}`;
    }

    let headers = {};
    if (localStorage.getItem("access") != null) {
        headers = {
            Authorization: "Bearer " + localStorage.getItem("access"),
            "Content-Type": "application/json"
        };
    }

    return (dispatch) => {
        dispatch(setFetchSingleCollectionStatus("LOADING"));
        return fetch(url, {
            method: "GET",
            headers: headers
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                // Set Dashboard Analytics Video List
                dispatch(setVideoList(data));
                dispatch(setVideoListStatus("LOADED"));

                dispatch(setFetchSingleCollectionStatus("LOADED"));
                return dispatch(setSingleCollection(data));
            })
            .catch((error) => {
                dispatch(setFetchSingleCollectionStatus("FAILED"));
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchSingleCollection(
                                collectionId,
                                unlistedKey,
                                promoCode,
                                handleCollectionNotFound
                            )
                        )
                    );
                } else if (parseInt(error.message) === 404) {
                    return handleCollectionNotFound();
                }
                return console.log(error);
            });
    };
};

export const fetchSingleCollectionAccess = (collectionId) => {
    return (dispatch) => {
        let headers = {};
        if (localStorage.getItem("access") != null) {
            headers = {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            };
        }
        dispatch(setFetchSingleCollectionAccessStatus("LOADING"));
        return fetch(
            API_URL + `/api/v1/collections/${collectionId}/module-access/`,
            {
                method: "GET",
                headers: headers
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setFetchSingleCollectionAccessStatus("LOADED"));
                return dispatch(setSingleCollectionAccess(data.moduleAccesses));
            })
            .catch((error) => {
                dispatch(setFetchSingleCollectionAccessStatus("FAILED"));
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchSingleCollectionAccess(collectionId)
                        )
                    );
                }
            });
    };
};

export const setFetchSingleCollectionAccessStatus = (status) => {
    return {
        type: "SET_FETCH_SINGLE_COLLECTION_ACCESS_STATUS",
        status
    };
};

export const setFetchSingleCollectionStatus = (status) => {
    return {
        type: "SET_FETCH_SINGLE_COLLECTION_STATUS",
        status
    };
};

export const setSingleCollectionAccess = (accessData) => {
    return {
        type: "SET_SINGLE_COLLECTION_ACCESS",
        accessData
    };
};

export const setFetchingCollectionsStatus = (status) => {
    return {
        type: "SET_FETCHING_COLLECTIONS_STATUS",
        status
    };
};

export const setFetchingCollectionCategoriesStatus = (status) => {
    return {
        type: "SET_FETCHING_COLLECTION_CATEGORIES_STATUS",
        status
    };
};
export const fetchOriginalCollections = () => {
    return (dispatch) => {
        let headers = {};
        if (localStorage.getItem("access") != null) {
            headers = {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            };
        }

        dispatch(setFetchingCollectionsStatus("LOADING"));
        return fetch(API_URL + `/api/v1/collections/`, {
            method: "GET",
            headers: headers
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setOriginalCollections(data));
                return dispatch(setFetchingCollectionsStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() => fetchOriginalCollections())
                    );
                }
                dispatch(setFetchingCollectionsStatus("FAILED"));
            });
    };
};

export const fetchCollectionCategories = () => {
    return (dispatch) => {
        let headers = {};
        if (localStorage.getItem("access") != null) {
            headers = {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            };
        }

        dispatch(setFetchingCollectionCategoriesStatus("LOADING"));
        return fetch(API_URL + `/api/v1/categories/`, {
            method: "GET",
            headers: headers
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setCollectionCategories(data));
                return dispatch(
                    setFetchingCollectionCategoriesStatus("LOADED")
                );
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() => fetchCollectionCategories())
                    );
                }
                dispatch(setFetchingCollectionCategoriesStatus("FAILED"));
            });
    };
};

export const getVideoData = (collectionId, moduleId, videoId) => {
    return {
        type: "GET_VIDEO_DATA",
        collectionId,
        moduleId,
        videoId
    };
};

export const getTranscript = (videoId) => {
    return (dispatch) => {
        dispatch(setTranscriptStatus("LOADING"));
        return fetch(API_URL + `/api/v1/videos/${videoId}/transcripts/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(saveTranscript(data));
                dispatch(setTranscriptStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(getNewAccessToken(() => getTranscript(videoId)));
                }
            });
    };
};

// Retrieves quiz questions for a single video
export const getQuizQuestions = (collectionId, moduleId, videoId) => {
    return (dispatch) => {
        dispatch(clearQuizQuestions());
        dispatch(setQuizQuestionStatus("LOADING"));
        return fetch(
            API_URL +
                `/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/quizquestions/`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(saveQuizQuestions(data));
                dispatch(setQuizQuestionStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() => getQuizQuestions(videoId))
                    );
                }
            });
    };
};

export const setLatestQuizQuestionAttempt = (questionId, attemptData) => {
    return {
        type: "SET_LATEST_QUIZ_QUESTION_ATTEMPT",
        questionId,
        attemptData
    };
};

export const submitQuizQuestionAttempt = (
    collectionId,
    moduleId,
    videoId,
    questionId,
    selectedAnswers,
    answerText = "",
    imageId = null,
    finishSubmission
) => {
    return (dispatch) => {
        return fetch(
            API_URL +
                `/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/quizquestions/${questionId}/attempts/`,
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    selectedOptions: selectedAnswers,
                    answerText: answerText,
                    answerImageId: imageId
                })
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setLatestQuizQuestionAttempt(questionId, data));
                finishSubmission();
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            submitQuizQuestionAttempt(
                                collectionId,
                                moduleId,
                                videoId,
                                questionId,
                                selectedAnswers,
                                answerText,
                                imageId,
                                finishSubmission
                            )
                        )
                    );
                } else {
                    message.error("An error has occured.");
                    finishSubmission();
                }
            });
    };
};

export const fetchVideoQnA = (collectionId, moduleId, videoId, couponId) => {
    return (dispatch) => {
        dispatch(setVideoQnAStatus("LOADING"));
        return fetch(
            API_URL +
                `/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/qnaquestions/?coupon=${couponId}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setVideoQnAData(data));
                dispatch(setVideoQnAStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchVideoQnA(
                                collectionId,
                                moduleId,
                                videoId,
                                couponId
                            )
                        )
                    );
                } else {
                    dispatch(setVideoQnAStatus("FAILED"));
                }
            });
    };
};

export const fetchModuleQnA = (collectionId, moduleId, couponId) => {
    return (dispatch) => {
        dispatch(setModuleQnAStatus("LOADING"));
        return fetch(
            API_URL +
                `/api/v1/collections/${collectionId}/modules/${moduleId}/qnaquestions/?coupon=${couponId}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setModuleQnAData(data));
                dispatch(setModuleQnAStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchModuleQnA(collectionId, moduleId, couponId)
                        )
                    );
                } else {
                    dispatch(setModuleQnAStatus("FAILED"));
                }
            });
    };
};

export const fetchCollectionQnA = (collectionId, couponId) => {
    return (dispatch) => {
        dispatch(setCollectionQnAStatus("LOADING"));
        return fetch(
            API_URL +
                `/api/v1/collections/${collectionId}/qnaquestions/?coupon=${couponId}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setCollectionQnAData(data));
                dispatch(setCollectionQnAStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchCollectionQnA(collectionId, couponId)
                        )
                    );
                } else {
                    dispatch(setCollectionQnAStatus("FAILED"));
                }
            });
    };
};

export const postQnAQuestion = (
    collectionId,
    moduleId,
    videoId,
    couponId,
    qnaLevel,
    formData,
    handleAfterSuccess
) => {
    let url;
    if (qnaLevel == "collection") {
        url = API_URL + `/api/v1/collections/${collectionId}/qnaquestions/`;
    } else if (qnaLevel == "module") {
        url =
            API_URL +
            `/api/v1/collections/${collectionId}/modules/${moduleId}/qnaquestions/`;
    } else {
        url =
            API_URL +
            `/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/qnaquestions/`;
    }
    return (dispatch) => {
        return fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                text: formData["text"],
                anonymous: formData["anonymous"],
                coupon: couponId
            })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(addNewQnAQuestion(qnaLevel, data));
                handleAfterSuccess();
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            postQnAQuestion(
                                collectionId,
                                moduleId,
                                videoId,
                                formData
                            )
                        )
                    );
                } else {
                    console.log(error);
                }
            });
    };
};

export const postQnAReply = (
    collectionId,
    moduleId,
    videoId,
    questionId,
    couponId,
    qnaLevel,
    formData,
    handleAfterSuccess
) => {
    let url;
    if (qnaLevel == "collection") {
        url =
            API_URL +
            `/api/v1/collections/${collectionId}/qnaquestions/${questionId}/qnaanswers/`;
    } else if (qnaLevel == "module") {
        url =
            API_URL +
            `/api/v1/collections/${collectionId}/modules/${moduleId}/qnaquestions/${questionId}/qnaanswers/`;
    } else {
        url =
            API_URL +
            `/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/qnaquestions/${questionId}/qnaanswers/`;
    }

    return (dispatch) => {
        return fetch(url, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                text: formData["text"],
                anonymous: formData["anonymous"],
                coupon: couponId
            })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(addNewQnAReply(qnaLevel, questionId, data)).then(
                    () => {
                        handleAfterSuccess();
                    }
                );
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            postQnAReply(
                                collectionId,
                                moduleId,
                                videoId,
                                couponId,
                                formData,
                                handleAfterSuccess
                            )
                        )
                    );
                } else {
                    console.log(error);
                }
            });
    };
};

export const deleteQnAReply = (
    questionId,
    answerId,
    qnaLevel,
    setDeleteLoading
) => {
    return (dispatch) => {
        return fetch(API_URL + `/api/v1/qnaanswers/${answerId}/`, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    message.success("Deleted");
                    dispatch(
                        removeDeletedReply(questionId, answerId, qnaLevel)
                    );
                    return;
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                // do nothing
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            deleteQnAReply(questionId, answerId, qnaLevel)
                        )
                    );
                } else if (parseInt(error.message) == 404) {
                    setDeleteLoading(false);
                    message.error("Comment could not be found.");
                } else {
                    setDeleteLoading(false);
                    console.log(error);
                }
            });
    };
};

export const removeQnAQuestion = (
    collectionId,
    moduleId,
    videoId,
    questionId,
    qnaLevel,
    setRemoveLoading
) => {
    return (dispatch) => {
        let url;
        if (qnaLevel == "collection") {
            url =
                API_URL +
                `/api/v1/collections/${collectionId}/qnaquestions/${questionId}/`;
        } else if (qnaLevel == "module") {
            url =
                API_URL +
                `/api/v1/collections/${collectionId}/modules/${moduleId}/qnaquestions/${questionId}/`;
        } else {
            url =
                API_URL +
                `/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/qnaquestions/${questionId}/`;
        }
        return fetch(url, {
            method: "PATCH",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                removed: true
            })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(updateRemovedQnAQuestion(qnaLevel, questionId, data));
                setRemoveLoading(false);
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            removeQnAQuestion(
                                collectionId,
                                moduleId,
                                videoId,
                                questionId,
                                qnaLevel,
                                setRemoveLoading
                            )
                        )
                    );
                } else if (parseInt(error.message) == 404) {
                    setRemoveLoading(false);
                    message.error("Comment could not be found.");
                } else {
                    setRemoveLoading(false);
                    console.log(error);
                }
            });
    };
};

export const deleteQnAQuestion = (
    collectionId,
    moduleId,
    videoId,
    questionId,
    qnaLevel,
    setDeleteLoading
) => {
    return (dispatch) => {
        let url;
        if (qnaLevel == "collection") {
            url =
                API_URL +
                `/api/v1/collections/${collectionId}/qnaquestions/${questionId}/`;
        } else if (qnaLevel == "module") {
            url =
                API_URL +
                `/api/v1/collections/${collectionId}/modules/${moduleId}/qnaquestions/${questionId}/`;
        } else {
            url =
                API_URL +
                `/api/v1/collections/${collectionId}/modules/${moduleId}/videos/${videoId}/qnaquestions/${questionId}/`;
        }
        return fetch(url, {
            method: "DELETE",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    message.success("Deleted");
                    dispatch(removeDeletedQnAQuestion(questionId, qnaLevel));
                    return;
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                // do nothing
            })
            .catch((error) => {
                if (parseInt(error.message) == 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            deleteQnAQuestion(
                                collectionId,
                                moduleId,
                                videoId,
                                questionId,
                                qnaLevel,
                                setDeleteLoading
                            )
                        )
                    );
                } else if (parseInt(error.message) == 404) {
                    setDeleteLoading(false);
                    message.error("Comment could not be found.");
                } else {
                    setDeleteLoading(false);
                    console.log(error);
                }
            });
    };
};
export const updateRemovedQnAQuestion = (
    qnaLevel,
    questionId,
    updatedQnAQuestion
) => {
    return {
        type: "UPDATE_REMOVED_QNA_QUESTION",
        questionId,
        qnaLevel,
        updatedQnAQuestion
    };
};

export const removeDeletedReply = (questionId, answerId, qnaLevel) => {
    return {
        type: "REMOVE_DELETED_REPLY",
        questionId,
        answerId,
        qnaLevel
    };
};

export const removeDeletedQnAQuestion = (questionId, qnaLevel) => {
    return {
        type: "REMOVE_DELETED_QNA_QUESTION",
        questionId,
        qnaLevel
    };
};
