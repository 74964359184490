const initialState = {
    collection: null,
    classMedianTimeSpent: [],
    classMedianTimeSpentStatus: "NOT LOADED",
    classStatistics: null,
    classStatisticsStatus: "NOT LOADED",
    dashboardReports: [],
    dashboardReportsStatus: "NOT LOADED",
    dashboardReportRequestStatus: "NOT LOADED",
    userPoV: null,
    homePageVideoActivityDataStatus: "NOT LOADED",
    homePageVideoActivityData: [],
    homePageRosterActivityDataStatus: "NOT LOADED",
    homePageRosterActivityData: [],
    moduleWatchPercentageData: null,
    moduleWatchPercentageDataStatus: "NOT LOADED",
    selectedCoupon: null,
    scheduledAccesses: [],
    recentQnAActivity: [],
    recentQnAActivityStatus: "NOT LOADED",
    roster: [],
    qnAStatistics: null,
    qnAStatisticsStatus: "NOT LOADED",
    quizQuestionStatistics: null,
    quizQuestionStatisticsStatus: "NOT LOADED",
    quizQuestionAttemptData: [],
    quizQuestionAttemptDataStatus: "NOT LOADED",
    studentSummaries: null,
    studentCourseSummariesStatus: "NOT LOADED",
    studentCourseSummaries: null,
    studentCourseVideoActivities: null,
    studentCourseVideoActivitiesStatus: "NOT LOADED",
    studentSummariesStatus: "NOT LOADED",
    userWatchHistoryOfModule: {},
    userWatchHistoryOfModuleStatus: "NOT LOADED",
    userQnAQuestionsByLevel: [],
    userQnAQuestionsByLevelStatus: "NOT LOADED",
    videoList: [],
    videoListStatus: "NOT LOADED",
    videoStatistics: null,
    videoStatisticsStatus: "NOT LOADED"
};

export default (state = initialState, action) => {
    let updatedRecentQnAActivity;
    let questionToUpdate;
    switch (action.type) {
        case "ADD_NEW_QNA_REPLY_FROM_RECENT":
            updatedRecentQnAActivity = [...state.recentQnAActivity];
            questionToUpdate = updatedRecentQnAActivity.find(
                (question) => question.id === action.questionId
            );
            questionToUpdate.responses = [
                ...questionToUpdate.responses,
                action.data
            ];
            return Object.assign({}, state, {
                recentQnAActivity: updatedRecentQnAActivity
            });
        case "CLEAR_DASHBOARD_DATA":
            return Object.assign({}, state, {
                collection: null,
                classMedianTimeSpent: [],
                classMedianTimeSpentStatus: "NOT LOADED",
                classStatistics: null,
                classStatisticsStatus: "NOT LOADED",
                dashboardReports: [],
                dashboardReportsStatus: "NOT LOADED",
                dashboardReportRequestStatus: "NOT LOADED",
                homePageVideoActivityDataStatus: "NOT LOADED",
                homePageVideoActivityData: [],
                homePageRosterActivityDataStatus: "NOT LOADED",
                homePageRosterActivityData: [],
                moduleWatchPercentageData: null,
                moduleWatchPercentageDataStatus: "NOT LOADED",
                scheduledAccesses: [],
                roster: [],
                videoList: [],
                videoListStatus: "NOT LOADED",
                userQnAQuestionsByLevel: [],
                userQnAQuestionsByLevelStatus: "NOT LOADED",
                quizQuestionAttemptData: [],
                quizQuestionAttemptDataStatus: "NOT LOADED",
                studentSummaries: null,
                studentSummariesStatus: "NOT LOADED",
                userWatchHistoryOfModule: {},
                userWatchHistoryOfModuleStatus: "NOT LOADED",
                recentQnAActivity: [],
                recentQnAActivityStatus: "NOT LOADED",
                qnAStatistics: null,
                qnAStatisticsStatus: "NOT LOADED",
                quizQuestionStatistics: null,
                quizQuestionStatisticsStatus: "NOT LOADED",
                videoStatistics: null,
                videoStatisticsStatus: "NOT LOADED"
            });
        case "CLEAR_VIDEO_STATISTICS_DRAWER_DATA":
            return Object.assign({}, state, {
                qnAStatistics: null,
                qnAStatisticsStatus: "NOT LOADED",
                quizQuestionStatistics: null,
                quizQuestionStatisticsStatus: "NOT LOADED",
                videoStatistics: null,
                videoStatisticsStatus: "NOT LOADED"
            });
        case "SET_COLLECTION":
            return Object.assign({}, state, {
                collection: action.collectionData
            });
        case "SET_CLASS_MEDIAN_TIME_SPENT":
            return Object.assign({}, state, {
                classMedianTimeSpent: action.timeSpent
            });
        case "SET_CLASS_MEDIAN_TIME_SPENT_STATUS":
            return Object.assign({}, state, {
                classMedianTimeSpentStatus: action.status
            });
        case "SET_CLASS_STATISTICS":
            return Object.assign({}, state, {
                classStatistics: action.classStatistics
            });
        case "SET_CLASS_STATISTICS_STATUS":
            return Object.assign({}, state, {
                classStatisticsStatus: action.status
            });
        case "SET_DASHBOARD_REPORTS_STATUS":
            return Object.assign({}, state, {
                dashboardReportsStatus: action.status
            });
        case "SET_DASHBOARD_REPORTS":
            return Object.assign({}, state, {
                dashboardReports: action.data
            });
        case "APPEND_DASHBOARD_REPORT":
            return Object.assign({}, state, {
                dashboardReports: [action.data, ...state.dashboardReports]
            });
        case "SET_DASHBOARD_REPORT_REQUEST_STATUS":
            return Object.assign({}, state, {
                dashboardReportRequestStatus: action.status
            });
        case "SET_MODULE_WATCH_PERCENTAGE_DATA":
            return Object.assign({}, state, {
                moduleWatchPercentageData: action.moduleWatchPercentageData
            });
        case "SET_MODULE_WATCH_PERCENTAGE_DATA_STATUS":
            return Object.assign({}, state, {
                moduleWatchPercentageDataStatus: action.status
            });
        case "SET_SELECTED_COUPON":
            return Object.assign({}, state, {
                selectedCoupon: action.couponData
            });
        case "SET_SCHEDULED_ACCESSES":
            return Object.assign({}, state, {
                scheduledAccesses: action.scheduledAccesses
            });
        case "SET_QNA_STATISTICS_STATUS":
            return Object.assign({}, state, {
                qnAStatisticsStatus: action.status
            });
        case "SET_QNA_STATISTICS":
            return Object.assign({}, state, {
                qnAStatistics: action.data
            });
        case "SET_QUIZ_QUESTION_STATISTICS_STATUS":
            return Object.assign({}, state, {
                quizQuestionStatisticsStatus: action.status
            });
        case "SET_QUIZ_QUESTION_STATISTICS":
            return Object.assign({}, state, {
                quizQuestionStatistics: action.data
            });
        case "SET_RECENT_QNA_ACTIVITY":
            return Object.assign({}, state, {
                recentQnAActivity: action.recentActivity
            });
        case "SET_RECENT_QNA_ACTIVITY_STATUS":
            return Object.assign({}, state, {
                recentQnAActivityStatus: action.status
            });
        case "SET_QUIZ_QUESTION_ATTEMPT_DATA":
            return Object.assign({}, state, {
                quizQuestionAttemptData: action.attemptData
            });
        case "SET_QUIZ_QUESTION_ATTEMPT_DATA_STATUS":
            return Object.assign({}, state, {
                quizQuestionAttemptDataStatus: action.status
            });
        case "SET_STUDENT_SUMMARIES":
            return Object.assign({}, state, {
                studentSummaries: action.data
            });
        case "SET_STUDENT_SUMMARIES_STATUS":
            return Object.assign({}, state, {
                studentSummariesStatus: action.status
            });
        case "SET_STUDENT_COURSE_SUMMARY_DATA":
            return Object.assign({}, state, {
                studentCourseSummaries: action.data
            });
        case "SET_STUDENT_COURSE_SUMMARY_STATUS":
            return Object.assign({}, state, {
                studentCourseSummariesStatus: action.status
            });
        case "SET_STUDENT_COURSE_VIDEO_ACTIVITIES_DATA":
            return Object.assign({}, state, {
                studentCourseVideoActivities: action.data
            });
        case "SET_STUDENT_COURSE_VIDEO_ACTIVITIES_STATUS":
            return Object.assign({}, state, {
                studentCourseVideoActivitiesStatus: action.status
            });
        case "SET_USER_WATCH_HISTORY_OF_MODULE":
            return Object.assign({}, state, {
                userWatchHistoryOfModule: {
                    ...state.userWatchHistoryOfModule,
                    [action.openModuleKey]: action.watchHistory
                }
            });
        case "SET_USER_WATCH_HISTORY_OF_MODULE_STATUS":
            return Object.assign({}, state, {
                userWatchHistoryOfModuleStatus: action.status
            });
        case "SET_USER_QNA_QUESTIONS_BY_LEVEL":
            return Object.assign({}, state, {
                userQnAQuestionsByLevel: action.qnaQuestions
            });
        case "SET_USER_QNA_QUESTIONS_BY_LEVEL_STATUS":
            return Object.assign({}, state, {
                userQnAQuestionsByLevelStatus: action.status
            });
        case "SET_ROSTER":
            return Object.assign({}, state, {
                roster: action.roster,
                numStudents: action.roster.length
                // filterdRoster: action.roster,
            });
        case "SET_VIDEO_STATISTICS":
            return Object.assign({}, state, {
                videoStatistics: action.data
            });
        case "SET_VIDEO_STATISTICS_STATUS":
            return Object.assign({}, state, {
                videoStatisticsStatus: action.status
            });
        case "SET_VIDEO_LIST":
            let videoList = action.collectionData.modules.flatMap((module) => {
                return module.videos.map((video) => {
                    let scheduledAccessObject =
                        state.selectedCoupon?.scheduledAccesses?.find(
                            (scheduledAccess) =>
                                scheduledAccess?.module == module.id
                        );
                    return {
                        collectionId: action.collectionData.id,
                        collectionName: action.collectionData.name,
                        moduleId: module.id,
                        moduleName: module.name,
                        videoId: video.id,
                        videoTitle: video.title,
                        videoType: video.videoType,
                        embedId: video.embedId,
                        privacyScope: action.collectionData.privacyScope,
                        unlistedKey:
                            action.collectionData.collectionUnlistedKey,
                        accessFrom: scheduledAccessObject
                            ? scheduledAccessObject.accessFrom
                            : null,
                        accessUntil: scheduledAccessObject
                            ? scheduledAccessObject.accessUntil
                            : null
                    };
                });
            });
            return Object.assign({}, state, {
                videoList: videoList
            });
        case "SET_VIDEO_LIST_STATUS":
            return Object.assign({}, state, {
                videoListStatus: action.status
            });
        case "SET_HOME_PAGE_VIDEO_ACTIVITY_DATA_STATUS":
            return Object.assign({}, state, {
                homePageVideoActivityDataStatus: action.status
            });
        case "SET_HOME_PAGE_VIDEO_ACTIVITY_DATA":
            return Object.assign({}, state, {
                homePageVideoActivityData: action.data
            });
        case "SET_HOME_PAGE_ROSTER_ACTIVITY_DATA_STATUS":
            return Object.assign({}, state, {
                homePageRosterActivityDataStatus: action.status
            });
        case "SET_HOME_PAGE_ROSTER_ACTIVITY_DATA":
            return Object.assign({}, state, {
                homePageRosterActivityData: action.data
            });
        default:
            return state;
    }
};
