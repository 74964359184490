import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

import { Button, Result, Spin } from "antd";
import { getLoginUrl, getSignUpUrl } from "./utils";

const AdminRoute = ({
    currentUser,
    fetchingUserStatus,
    isAuthenticated,
    ...props
}) => {
    if (
        fetchingUserStatus === "SUCCESS" &&
        isAuthenticated &&
        currentUser &&
        currentUser.isAdmin
    ) {
        return <Route {...props} />;
    } else if (
        fetchingUserStatus === "SUCCESS" &&
        isAuthenticated &&
        currentUser &&
        !currentUser.isAdmin
    ) {
        return <Redirect to="/" />;
    } else if (fetchingUserStatus === "FAILED") {
        return (
            <div className="centered-content">
                <Result
                    status="403"
                    title="403"
                    subTitle="You must be signed in to access this page"
                    extra={[
                        <Button className="login-btn" type="primary">
                            <a href={`${getSignUpUrl()}`}>Sign Up</a>
                        </Button>,
                        <Button className="login-btn" ghost>
                            <a href={`${getLoginUrl()}`}>Login</a>
                        </Button>
                    ]}
                />
            </div>
        );
    } else {
        return (
            <div className="loading-authentication">
                <Spin />
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        fetchingUserStatus: state.userReducer.fetchingUserStatus,
        isAuthenticated: state.userReducer.isAuthenticated,
        currentUser: state.userReducer.user
    };
};
export default connect(mapStateToProps, {})(AdminRoute);
