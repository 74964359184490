import React, { Component } from "react";
import { Layout, Menu } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import { Switch, Route, Link, withRouter } from "react-router-dom";
import AuthenticatedRoute from "./AuthenticatedRoute";
import Home from "./Home";
import LoginLogoutButtons from "./LoginLogoutButtons";
import PremiumCollectionList from "./Collections/List/PremiumCollectionList";
import CollectionCreator from "./CollectionCreator/CollectionCreator";
import CollectionEditorWrapper from "./CollectionEditor/CollectionEditorWrapper";
import CreatorSignup from "./CreatorSignup";
import FollowUp from "./Marketing/FollowUp";
import CreatorAboutPage from "./CreatorPages/CreatorAboutPage";
import AuthorProfilePage from "./CreatorPages/AuthorProfilePage";
import DashboardHome from "./Dashboard/Home/DashboardHome";
import CreatedCollectionDetail from "./CreatedCollectionDetail";
import ModuleCreator from "./ModuleCreator/ModuleCreator";
import PurchasedContent from "./Collections/PurchasedContent/PurchasedContent";
import ReportIssuePage from "./ReportIssuePage";
import UsedCouponPage from "./UsedCoupons/UsedCouponPage";
import QuestionCreator from "./QuestionsCreator/QuestionCreator";
import VideoPage from "./Collections/Video/VideoPage";
import WalletPage from "./Wallet/WalletPage";
import PageNotFound from "./PageNotFound";
import {
    fetchOriginalCollections,
    fetchCollectionCategories
} from "./actions/collection.js";
import { fetchJWTTokens, fetchUserData } from "./actions/user.js";
//import Hugo from './Hugo';
import "./App.less";
import MockDashboardHeader from "./Marketing/Dashboard/MockDashboardHeader";
import MockDashboardRoster from "./Marketing/Dashboard/MockDashboardRoster";
import MockDashboardRosterList from "./Marketing/Dashboard/MockDashboardRosterList";
import StudentHome from "./Dashboard/Students/StudentHome";
import DashboardPageHeader from "./Dashboard/DashboardPageHeader";
import IndividualStudentPage from "./Dashboard/Students/IndividualStudentPage";
import VideoHome from "./Dashboard/Videos/VideoHome";
import IndividualVideoPage from "./Dashboard/Videos/IndividualVideoPage";
import DashboardQnAHome from "./Dashboard/QnA/DashboardQnAHome";
import DashboardCollectionQnA from "./Dashboard/QnA/DashboardCollectionQnA";
import DashboardModuleQnA from "./Dashboard/QnA/DashboardModuleQnA";
import DashboardVideoQnA from "./Dashboard/QnA/DashboardVideoQnA";
import AboutUs from "./AboutUs";
import DashboardGeneratedReports from "./Dashboard/GeneratedReports/DashboardGeneratedReports";
import PremiumCollectionDetail from "./Collections/List/PremiumCollectionDetail";
import PremiumCollectionCertificationDetail from "./Collections/Certification/PremiumCollectionDetail";

import CreatorHome from "./CreatorHome";
import AccountTypeRegistrationModal from "./AccountTypeRegistration/AccountTypeRegistrationModal";
import CreatedModuleDetail from "./CreatedModuleDetail";
import FAQ from "./FAQ";
import AdminHome from "./Admin/AdminHome";
import AdminTranscripts from "./Admin/AdminTranscripts";
import AdminClassCodes from "./Admin/AdminClassCodes";
import AdminClassCodeRoster from "./Admin/AdminClassCodeRoster";
import AdminRoute from "./AdminRoute";
import StudentWalkthrough from "./Walkthrough/StudentWalkthrough";
import InstructorWalkthrough from "./Walkthrough/InstructorWalkthrough";
import cubitslogo from "./cubits-logo.png";
import CreatorIntroPage from "./CreatorIntroPage";
import ContactUsPage from "./ContactUsPage";
import DemoRequestPage from "./DemoRequest/DemoRequestPage";

import AdminDemoRequests from "./Admin/AdminDemoRequests";
import MockVideoPlayer from "./Marketing/MockVideoPlayer";
import MockCollection from "./Marketing/Collection/MockCollection";
import MockDashboardHome from "./Marketing/Dashboard/MockDashboardHome";
import MockDashboardQnA from "./Marketing/Dashboard/MockDashboardQnA";
import MockDashboardVideoAnalytics from "./Marketing/Video/MockDashboardVideoAnalytics";
import MockDashboardGeneratedReports from "./Marketing/Dashboard/MockDashboardGeneratedReports";
import AdminCertificateRequests from "./Admin/AdminCertificateRequests";
import AdminCertificateRequestDetails from "./Admin/AdminCertificateRequestDetails";
import AuthorsPage from "./AuthorsPage";
import Certifications from "./Certifications";
import CertifiedCollectionList from "./Collections/List/CertifiedCollectionList.js";

const { Header, Content, Footer } = Layout;

class App extends Component {
    componentDidMount() {
        this.props.fetchOriginalCollections();
        this.props.fetchCollectionCategories();
        if (localStorage.getItem("access") === null) {
            this.props.fetchJWTTokens();
        } else if (localStorage.getItem("access") !== null) {
            this.props.fetchUserData();
        }
    }
    componentDidUpdate(prevProps) {
        if (
            prevProps.requireLogin !== this.props.requireLogin &&
            prevProps.requireLogin === true
        ) {
            this.props.fetchUserData();
        }
    }

    render() {
        return (
            <Layout>
                <Header id="header">
                    <Link className="cubits-logo-anchor" to="/">
                        <img alt="cubits-logo" src={cubitslogo} />
                    </Link>
                    <div id="header-menus">
                        <Link to="/collections/" className="header-menu">
                            Premium Courses
                        </Link>
                        {this.props.isAuthenticated ? (
                            <Link to="/my-collections/" className="header-menu">
                                My Courses
                            </Link>
                        ) : null}
                        {!this.props.isAuthenticated ? (
                            <Link to="/authors/" className="header-menu">
                                Authors
                            </Link>
                        ) : (
                            <Link to="/dashboard/" className="header-menu">
                                Dashboard
                            </Link>
                        )}
                        <Link to="/certifications/" className="header-menu">
                            Certifications
                        </Link>
                        {!this.props.isAuthenticated ? (
                            <Link to="/aboutus/" className="header-menu">
                                About Us
                            </Link>
                        ) : null}
                        {!this.props.isAuthenticated ? (
                            <Link
                                to="/frequently-asked-questions/"
                                className="header-menu">
                                FAQ
                            </Link>
                        ) : null}

                        <LoginLogoutButtons user={this.props.user || ""} />
                    </div>
                </Header>
                <Content
                    className="site-layout"
                    style={{ marginTop: 48, minHeight: "calc(100vh - 134px)" }}>
                    {(this.props.isAuthenticated &&
                        !this.props.user.completedAccountTypeRegistration) ||
                    this.props.accountTypeRegistrationStatus === "LOADING" ||
                    this.props.accountTypeRegistrationStatus === "SUCCESS" ? (
                        <AccountTypeRegistrationModal />
                    ) : (
                        <Switch>
                            <Route path="/" exact component={Home} />
                            <Route
                                path="/frequently-asked-questions/"
                                exact
                                component={FAQ}
                            />
                            <Route path="/aboutus/" exact component={AboutUs} />
                            <Route
                                path="/creator/"
                                exact
                                component={CreatorIntroPage}
                            />
                            <Route
                                path="/authors/"
                                exact
                                component={AuthorsPage}
                            />
                            <Route
                                path="/certifications/"
                                exact
                                component={Certifications}
                            />
                            <Route
                                path="/contactus/"
                                exact
                                component={ContactUsPage}
                            />
                            <Route
                                path="/demo/"
                                eact
                                component={DemoRequestPage}
                            />
                            <AuthenticatedRoute
                                path="/collections/create/"
                                exact
                                component={CollectionCreator}
                            />
                            <AuthenticatedRoute
                                path="/collections/:collectionId/edit/"
                                exact
                                component={CollectionCreator}
                            />
                            {/* <AuthenticatedRoute
                                path="/collections/:collectionId/edit/"
                                exact
                                component={CollectionEditorWrapper}
                            /> */}
                            <AuthenticatedRoute
                                path="/collections/:collectionId/modules/:moduleIndex/videos/:videoIndex"
                                exact
                                component={VideoPage}
                            />
                            <Route
                                path="/collections/:collectionId/modules/:moduleId"
                                exact
                                component={PremiumCollectionDetail}
                            />
                            <Route
                                path="/collections/:collectionId/certification/"
                                exact
                                component={PremiumCollectionCertificationDetail}
                            />
                            <Route
                                path="/collections/:collectionId"
                                exact
                                component={PremiumCollectionDetail}
                            />
                            <Route
                                path="/collections/"
                                exact
                                component={PremiumCollectionList}
                            />
                            <Route
                                path="/certification-collections/"
                                exact
                                component={CertifiedCollectionList}
                            />
                            <Route
                                path="/mock/collection"
                                exact
                                component={MockCollection}
                            />
                            <Route path="/mock/dashboard">
                                <div id="mock-dashboard-home">
                                    <MockDashboardHeader />
                                    <Route
                                        path="/mock/dashboard"
                                        exact
                                        component={MockDashboardHome}
                                    />
                                    <Route
                                        path="/mock/dashboard/roster"
                                        exact
                                        component={MockDashboardRosterList}
                                    />
                                    <Route
                                        path="/mock/dashboard/roster/sample"
                                        exact
                                        component={MockDashboardRoster}
                                    />
                                    <Route
                                        path="/mock/dashboard/qna"
                                        exact
                                        component={MockDashboardQnA}
                                    />
                                    <Route
                                        path="/mock/dashboard/videos"
                                        exact
                                        component={MockDashboardVideoAnalytics}
                                    />
                                    <Route
                                        path="/mock/dashboard/reports"
                                        exact
                                        component={
                                            MockDashboardGeneratedReports
                                        }
                                    />
                                </div>
                            </Route>
                            <Route
                                path="/mock/complete"
                                exact
                                component={FollowUp}
                            />

                            <Route
                                path="/mock/video"
                                exact
                                component={MockVideoPlayer}
                            />
                            <AuthenticatedRoute
                                path="/creator/home/"
                                exact
                                component={CreatorHome}
                            />
                            <AuthenticatedRoute
                                path="/creator/created-collections/:collectionId"
                                exact
                                component={CreatedCollectionDetail}
                            />
                            <AuthenticatedRoute
                                path="/creator/created-modules/:moduleId"
                                exact
                                component={CreatedModuleDetail}
                            />
                            <Route
                                path="/creators/:userId/"
                                exact
                                component={CreatorAboutPage}
                            />
                            <AuthenticatedRoute path="/dashboard">
                                <div id="dashboard-home">
                                    <DashboardPageHeader />

                                    <Switch>
                                        <AuthenticatedRoute
                                            path="/dashboard/"
                                            exact
                                            component={DashboardHome}
                                        />
                                        <AuthenticatedRoute
                                            path="/dashboard/reports/"
                                            exact
                                            component={
                                                DashboardGeneratedReports
                                            }
                                        />
                                        <AuthenticatedRoute
                                            path="/dashboard/qna/"
                                            exact
                                            component={DashboardQnAHome}
                                        />
                                        <AuthenticatedRoute
                                            path="/dashboard/qna/collections/:collectionId"
                                            exact
                                            component={DashboardCollectionQnA}
                                        />
                                        <AuthenticatedRoute
                                            path="/dashboard/qna/collections/:collectionId/modules/:moduleId"
                                            exact
                                            component={DashboardModuleQnA}
                                        />
                                        <AuthenticatedRoute
                                            path="/dashboard/qna/collections/:collectionId/modules/:moduleId/videos/:videoId"
                                            exact
                                            component={DashboardVideoQnA}
                                        />
                                        <AuthenticatedRoute
                                            path="/dashboard/students/"
                                            exact
                                            component={StudentHome}
                                        />
                                        <AuthenticatedRoute
                                            path="/dashboard/students/search/:searchTerm"
                                            exact
                                            component={StudentHome}
                                        />
                                        <AuthenticatedRoute
                                            path="/dashboard/students/:studentID"
                                            exact
                                            component={IndividualStudentPage}
                                        />
                                        <AuthenticatedRoute
                                            path="/dashboard/videos/"
                                            exact
                                            component={VideoHome}
                                        />
                                        <AuthenticatedRoute
                                            path="/dashboard/videos/search/:searchTerm"
                                            exact
                                            component={VideoHome}
                                        />
                                        <AuthenticatedRoute
                                            path="/dashboard/videos/:moduleId/:videoId/"
                                            exact
                                            component={IndividualVideoPage}
                                        />
                                    </Switch>
                                </div>
                            </AuthenticatedRoute>
                            <Route
                                path="/walkthrough/collection/"
                                exact
                                component={StudentWalkthrough}
                            />
                            <Route
                                path="/walkthrough/create-collection/"
                                exact
                                component={InstructorWalkthrough}
                            />
                            <AuthenticatedRoute
                                path="/modules/create/"
                                exact
                                component={ModuleCreator}
                            />
                            {/*<AuthenticatedRoute path='/modules/:moduleId/edit/' exact component={ModuleEditor} />*/}
                            <AuthenticatedRoute
                                path="/modules/:moduleId/videos/:videoId/questions/create/"
                                exact
                                component={QuestionCreator}
                            />
                            <AuthenticatedRoute
                                path="/my-collections/"
                                exact
                                component={PurchasedContent}
                            />
                            <AuthenticatedRoute
                                path="/account"
                                exact
                                component={WalletPage}
                            />
                            <AuthenticatedRoute
                                path="/scheduled-licenses"
                                exact
                                component={UsedCouponPage}
                            />
                            <AuthenticatedRoute
                                path="/report-issue/"
                                exact
                                component={ReportIssuePage}
                            />

                            <AdminRoute
                                path="/admin/classcodes/:classCode/roster/"
                                exact
                                component={AdminClassCodeRoster}
                            />
                            <AdminRoute
                                path="/admin/classcodes/"
                                exact
                                component={AdminClassCodes}
                            />
                            <AdminRoute
                                path="/admin/transcripts/"
                                exact
                                component={AdminTranscripts}
                            />
                            <AdminRoute
                                path="/admin/demos/"
                                exact
                                component={AdminDemoRequests}
                            />
                            <AdminRoute
                                path="/admin/certificate-requests/:requestId/"
                                exact
                                component={AdminCertificateRequestDetails}
                            />
                            <AdminRoute
                                path="/admin/certificate-requests/"
                                exact
                                component={AdminCertificateRequests}
                            />
                            <AdminRoute
                                path="/admin/"
                                exact
                                component={AdminHome}
                            />
                            <Route
                                path="/404/"
                                exact
                                component={PageNotFound}
                            />
                            <Route
                                path="/:authorSlug/"
                                exact
                                component={AuthorProfilePage}
                            />
                            <AuthenticatedRoute component={PageNotFound} />
                        </Switch>
                    )}
                </Content>
                <Footer id="footer">
                    <div id="footer-menu-container">
                        <img src={cubitslogo} />

                        <div className="footer-menu">
                            <h1>ABOUT US</h1>
                            <p>
                                <Link to="/aboutus/">Company</Link>
                            </p>
                            <p>
                                <Link to="/contactus/">Contact Us</Link>
                            </p>
                        </div>

                        <div className="footer-menu">
                            <h1>HELP</h1>
                            <p>
                                <Link to="/report-issue/">Report an Issue</Link>
                            </p>
                        </div>
                    </div>

                    <div>
                        support@cuvids.io | CUbits ©{new Date().getFullYear()}
                    </div>
                </Footer>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        accountTypeRegistrationStatus:
            state.userReducer.accountTypeRegistrationStatus,
        isAuthenticated: state.userReducer.isAuthenticated,
        user: state.userReducer.user,
        requireLogin: state.userReducer.requireLogin
    };
};
export default connect(mapStateToProps, {
    fetchOriginalCollections,
    fetchCollectionCategories,
    fetchJWTTokens,
    fetchUserData
})(withRouter(App));
