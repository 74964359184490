import ContactUsForm from "./Components/ContactUsForm";

const ContactUsPage = () => {
    return (
        <div className="centered-container">
            <ContactUsForm />
        </div>
    );
};

export default ContactUsPage;
