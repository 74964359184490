import { useEffect, useState } from "react";
import {
    fetchCollectionData,
    fetchStudentCourseSummary,
    fetchStudentCourseVideoActivities
} from "../actions/dashboard";
import { Button, Card, Modal, Skeleton, Typography } from "antd";
import { AreaChartOutlined } from "@ant-design/icons";
import { connect, useDispatch } from "react-redux";
import StudentSummaries from "../Dashboard/Students/StudentSummaries";
import CheckboxGrid from "../Dashboard/Students/CheckboxGrid";

const { Title } = Typography;

const StudentCourseAnalytics = ({
    collection,
    isAuthenticated,
    studentCourseSummaries,
    studentCourseSummariesStatus,
    studentCourseVideoActivitiesStatus,
    studentCourseVideoActivities,
    disabled = false
}) => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);

    const handleOnCancel = () => {
        setVisible(false);
    };

    const StudentCourseAnalyticsButton = () => {
        if (isAuthenticated) {
            return (
                <Button
                    onClick={() => {
                        setVisible(true);
                    }}
                    icon={<AreaChartOutlined />}
                    disabled={disabled}>
                    Dashboard
                </Button>
            );
        } else {
            return null;
        }
    };

    const ModalContent = () => {
        return (
            <>
                <Card className="dashboard-card">
                    <StudentSummaries
                        studentSummaries={studentCourseSummaries}
                        studentSummariesStatus={studentCourseSummariesStatus}
                    />
                </Card>

                <Card style={{ marginTop: "12px" }} className="dashboard-card">
                    <Title level={5}>Overview</Title>
                    {studentCourseVideoActivitiesStatus === "LOADED" ? (
                        <CheckboxGrid
                            watchedVideos={
                                studentCourseVideoActivities?.watchedVideos ||
                                {}
                            }
                        />
                    ) : (
                        <>
                            <p
                                style={{
                                    marginTop: 8,
                                    marginBottom: 8,
                                    fontStyle: "italic",
                                    color: "#888"
                                }}>
                                Loading...
                            </p>
                            <Skeleton
                                active
                                title={false}
                                paragraph={{ rows: 5 }}
                            />
                        </>
                    )}
                </Card>
            </>
        );
    };

    useEffect(() => {
        if (isAuthenticated && collection) {
            dispatch(fetchStudentCourseSummary(collection.id)).then(() => {});

            dispatch(fetchStudentCourseVideoActivities(collection.id)).then(
                () => {}
            );
        }
    }, [isAuthenticated, collection]);

    return (
        <>
            <StudentCourseAnalyticsButton />
            <Modal
                visible={visible}
                onCancel={handleOnCancel}
                footer={null}
                width={"80vw"}>
                <div className="modal-body">
                    <ModalContent />
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.userReducer.isAuthenticated,
        studentCourseSummaries: state.dashboardReducer.studentCourseSummaries,
        studentCourseSummariesStatus:
            state.dashboardReducer.studentCourseSummariesStatus,
        studentCourseVideoActivities:
            state.dashboardReducer.studentCourseVideoActivities,
        studentCourseVideoActivitiesStatus:
            state.dashboardReducer.studentCourseVideoActivitiesStatus
    };
};

export default connect(mapStateToProps, {
    fetchCollectionData,
    fetchStudentCourseSummary,
    fetchStudentCourseVideoActivities
})(StudentCourseAnalytics);
