import { after } from "lodash";
import { API_URL } from "../const.js";
import { getNewAccessToken } from "./user.js";

export const fetchCreatorProfile = (userId) => {
    return (dispatch) => {
        dispatch(setCreatorProfileStatus("LOADING"));
        let headers;
        if (localStorage.getItem("access") != null) {
            headers = {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            };
        } else {
            headers = {
                "Content-Type": "application/json"
            };
        }
        return fetch(API_URL + `/api/v1/creators/${userId}/profile/`, {
            method: "GET",
            headers: headers
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setCreatorProfile(data));
                dispatch(setCreatorProfileStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() => fetchCreatorProfile(userId))
                    );
                }
                dispatch(setCreatorProfileStatus("FAILED"));
            });
    };
};

export const fetchCreatorPublicProfile = (
    slug,
    handleCreatorContents,
    handleCreatorNotFound
) => {
    return (dispatch) => {
        dispatch(setCreatorProfileStatus("LOADING"));
        let headers;
        headers = {
            "Content-Type": "application/json"
        };
        return fetch(API_URL + `/api/v1/creators/profile/${slug}/`, {
            method: "GET",
            headers: headers
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                handleCreatorContents();
                dispatch(setCreatorProfile(data));
                dispatch(setCreatorProfileStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchCreatorPublicProfile(
                                slug,
                                handleCreatorContents,
                                handleCreatorNotFound
                            )
                        )
                    );
                } else if (parseInt(error.message) === 404) {
                    return handleCreatorNotFound();
                }
                dispatch(setCreatorProfileStatus("FAILED"));
            });
    };
};

export const setCreatorProfile = (creatorProfile) => {
    return {
        type: "SET_CREATOR_PROFILE",
        creatorProfile
    };
};

export const setCreatorProfileStatus = (status) => {
    return {
        type: "SET_CREATOR_PROFILE_STATUS",
        status
    };
};

export const fetchCreatorEarnings = (userId) => {
    return (dispatch) => {
        dispatch(setCreatorEarningsStatus("LOADING"));
        return fetch(API_URL + `/api/v1/creators/${userId}/earnings/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setCreatorEarnings(data));
                dispatch(setCreatorEarningsStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() => fetchCreatorEarnings(userId))
                    );
                }
                dispatch(setCreatorEarningsStatus("FAILED"));
            });
    };
};

export const setCreatorEarnings = (earnings) => {
    return {
        type: "SET_CREATOR_EARNINGS",
        earnings
    };
};

export const setCreatorEarningsStatus = (status) => {
    return {
        type: "SET_CREATOR_EARNINGS_STATUS",
        status
    };
};

export const fetchCreatorCollectionPerformances = (userId) => {
    return (dispatch) => {
        dispatch(setCreatorCollectionPerformancesStatus("LOADING"));
        return fetch(
            API_URL + `/api/v1/creators/${userId}/collections/performance/`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setCreatorCollectionPerformances(data));
                dispatch(setCreatorCollectionPerformancesStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchCreatorCollectionPerformances(userId)
                        )
                    );
                }
                dispatch(setCreatorCollectionPerformancesStatus("FAILED"));
            });
    };
};

export const setCreatorCollectionPerformances = (performances) => {
    return {
        type: "SET_CREATOR_COLLECTION_PERFORMANCES",
        performances
    };
};

export const setCreatorCollectionPerformancesStatus = (status) => {
    return {
        type: "SET_CREATOR_COLLECTION_PERFORMANCES_STATUS",
        status
    };
};

export const fetchCreatorModulePerformances = (userId) => {
    return (dispatch) => {
        dispatch(setCreatorModulePerformancesStatus("LOADING"));
        return fetch(
            API_URL + `/api/v1/creators/${userId}/modules/performance/`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setCreatorModulePerformances(data));
                dispatch(setCreatorModulePerformancesStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchCreatorModulePerformances(userId)
                        )
                    );
                }
                dispatch(setCreatorModulePerformancesStatus("FAILED"));
            });
    };
};

export const setCreatorModulePerformances = (performances) => {
    return {
        type: "SET_CREATOR_MODULE_PERFORMANCES",
        performances
    };
};

export const setCreatorModulePerformancesStatus = (status) => {
    return {
        type: "SET_CREATOR_MODULE_PERFORMANCES_STATUS",
        status
    };
};

export const updateCreatorProfile = (
    userId,
    updatedData,
    setUpdatingStatus,
    afterSuccess
) => {
    return (dispatch) => {
        setUpdatingStatus(true);
        return fetch(API_URL + `/api/v1/creators/${userId}/profile/update/`, {
            method: "PATCH",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                "Content-Type": "application/json"
            },
            body: JSON.stringify(updatedData)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setCreatorProfile(data));
                setUpdatingStatus(false);
                afterSuccess();
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            updateCreatorProfile(updatedData, setUpdatingStatus)
                        )
                    );
                }
                setUpdatingStatus(false);
            });
    };
};

export const fetchCreatorTransactionsSummary = (
    userId,
    pageIndex,
    updatePagination,
    updatePreviousValues
) => {
    return (dispatch) => {
        dispatch(setCreatorTransactionsSummaryStatus("LOADING"));
        return fetch(
            API_URL +
                `/api/v1/creators/${userId}/transactions/summary/?pageIndex=${pageIndex}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setCreatorTransactionsSummary(data));
                dispatch(setCreatorTransactionsSummaryStatus("LOADED"));
                updatePagination(data.currentPage, 5, 5 * data.numPages);
                updatePreviousValues(data.summaries);
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchCreatorTransactionsSummary(userId)
                        )
                    );
                }
                dispatch(setCreatorTransactionsSummaryStatus("FAILED"));
            });
    };
};

export const setCreatorTransactionsSummary = (transactionsSummary) => {
    return {
        type: "SET_CREATOR_TRANSACTIONS_SUMMARY",
        transactionsSummary
    };
};

export const setCreatorTransactionsSummaryStatus = (status) => {
    return {
        type: "SET_CREATOR_TRANSACTIONS_SUMMARY_STATUS",
        status
    };
};

export const fetchCreatorTransactionsByWeek = (userId, weeksAgo) => {
    return (dispatch) => {
        dispatch(setCreatorTransactionsByWeekStatus("LOADING"));
        return fetch(
            API_URL +
                `/api/v1/creators/${userId}/transactions/?weeksAgo=${weeksAgo}`,
            {
                method: "GET",
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access"),
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setCreatorTransactionsByWeek(data));
                dispatch(setCreatorTransactionsByWeekStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchCreatorTransactionsByWeek(userId, weeksAgo)
                        )
                    );
                }
                dispatch(setCreatorTransactionsByWeekStatus("FAILED"));
            });
    };
};

export const setCreatorTransactionsByWeek = (transactions) => {
    return {
        type: "SET_CREATOR_TRANSACTIONS_BY_WEEK",
        transactions
    };
};

export const setCreatorTransactionsByWeekStatus = (status) => {
    return {
        type: "SET_CREATOR_TRANSACTIONS_BY_WEEK_STATUS",
        status
    };
};

export const fetchCreatorPublicCollections = (userId) => {
    return (dispatch) => {
        dispatch(setCreatorPublicCollectionsStatus("LOADING"));
        return fetch(
            API_URL + `/api/v1/creators/${userId}/public-collections/`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setCreatorPublicCollections(data));
                dispatch(setCreatorPublicCollectionsStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchCreatorPublicCollections(userId)
                        )
                    );
                }
                dispatch(setCreatorPublicCollectionsStatus("FAILED"));
            });
    };
};

export const fetchCreatorPublicCertificationCourses = (slug) => {
    return (dispatch) => {
        dispatch(setCreatorPublicCollectionsStatus("LOADING"));
        return fetch(
            API_URL + `/api/v1/creators/profile/${slug}/certification-courses/`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                dispatch(setCreatorPublicCollections(data));
                dispatch(setCreatorPublicCollectionsStatus("LOADED"));
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    dispatch(
                        getNewAccessToken(() =>
                            fetchCreatorPublicCertificationCourses(slug)
                        )
                    );
                }
                dispatch(setCreatorPublicCollectionsStatus("FAILED"));
            });
    };
};

export const setCreatorPublicCollections = (collections) => {
    return {
        type: "SET_CREATOR_PUBLIC_COLLECTIONS",
        collections
    };
};

export const setCreatorPublicCollectionsStatus = (status) => {
    return {
        type: "SET_CREATOR_PUBLIC_COLLECTIONS_STATUS",
        status
    };
};
